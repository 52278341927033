<template>
  <el-table v-loading="loading" :data="list" class="staff-link--table" stripe>
    <el-table-column align="center" prop="user.name" label="Nome" />
    <el-table-column align="center" label="Ações">
      <template slot-scope="scope">
        <el-button
          v-if="checkIfAlreadyExistsOnBranch(scope.row)"
          size="mini"
          type="danger"
          @click="unlinkStaff(scope.row.id)"
        >Remover da filial</el-button>
        <el-button
          v-else
          size="mini"
          type="success"
          @click="linkStaff(scope.row.id)"
        >Incluir na filial</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
export default {
  props: {
    branchPropsStaffs: {
      type: Array,
      required: true
    },
    branchPropsId: {
      type: String,
      required: true
    },
    branchPropsName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      staffRole: "",
      isToAddStaff: null
    };
  },
  computed: {
    ...mapGetters({
      list: types.STAFF_LIST,
      storeID: types.AUTH_ACTIVE_STORE
    })
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.STAFF_LIST, { storeID: this.storeID });
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    checkIfAlreadyExistsOnBranch(staff) {
      if (staff.branches.find(branch => branch.id === this.branchPropsId)) {
        return true;
      } else {
        return false;
      }
    },
    async linkStaff(staffID) {
      this.loading = true;
      try {
        await this.$store.dispatch(types.BRANCH_LINK_STAFF, {
          storeID: this.storeID,
          branchID: this.branchPropsId,
          staffID
        });
        this.$notify.success("Adicionado com sucesso");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    },
    async unlinkStaff(staffID) {
      this.loading = true;
      try {
        await this.$store.dispatch(types.BRANCH_UNLINK_STAFF, {
          storeID: this.storeID,
          branchID: this.branchPropsId,
          staffID
        });
        this.$notify.success("Removido com sucesso");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.staff-link--table {
  margin-top: 1rem;
}
</style>
