<template>
  <div v-loading="mounting || loading" class="billets">
    <template v-loading="loading" v-if="subscription.allow_billets && subscription.iugu_activated">
      <section class="billets-stepper-wrapper" v-if="active !== 'step03'">
        <stepper
          :title="'Ativação de boleto'"
          :active="active === 'step01'"
          :completed="completed.includes('step01')"
        />
        <stepper
          :title="'Dados bancários'"
          :active="active === 'step02'"
          :completed="completed.includes('step02')"
        />
        <stepper
          :title="'Confirmação'"
          :active="active === 'step03'"
          :completed="completed.includes('step03')"
        />
      </section>
      <step01 v-if="active === 'step01'" @onNext="submitStep1" />
      <step02 v-else-if="active === 'step02'" @onNext="submitStep2" />
      <step03 v-else />
    </template>
    <template v-else>
      <div class="cannot-access">
        <h2>Você precisa fazer o upgrade do seu plano para emitir boletos para os seus clientes</h2>
        <br />
        <el-button type="success" @click="$router.push('/assinar-plano')">Ver planos</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import Stepper from "@/components/Settings/Billets/Stepper";
import Step01 from "./BilletsSteps/Step01";
import Step02 from "./BilletsSteps/Step02";
import Step03 from "./BilletsSteps/Step03";

export default {
  components: {
    Stepper,
    Step01,
    Step02,
    Step03
  },
  data() {
    return {
      billets: false,
      mass_billets: true,
      loading: true,
      submitting: false,
      mounting: true,
      active: "step01",
      completed: []
    };
  },
  computed: {
    ...mapGetters({
      info: types.STORE_INFO,
      storeID: types.AUTH_ACTIVE_STORE,
      subscription: types.SUBSCRIPTION_CONFIG,
      account: types.STORE_BANK_ACCOUNT
    })
  },
  watch: {
    info: function(newValue) {
      if (
        newValue.create_billets &&
        newValue.iugu_account_status !== "not_sent"
      ) {
        this.completed = ["step01", "step02", "step03"];
        this.active = "step03";
      }
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.STORE_INFO, this.storeID);
      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
      this.billets = this.info.create_billets;
      if (
        this.info.create_billets &&
        this.info.iugu_account_status !== "not_sent"
      ) {
        this.completed = ["step01", "step02", "step03"];
        this.active = "step03";
      }
      this.mounting = false;
      this.loading = false;
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    async submitStep1() {
      try {
        this.loading = true;
        await this.$store.dispatch(types.STORE_INFO_UPDATE, {
          storeID: this.storeID,
          payload: { store: { create_billets: true } }
        });
        await this.$store.dispatch(types.STORE_TOGGLE_BILLETS, {
          storeID: this.storeID,
          payload: { set: true }
        });
        this.loading = false;
        this.completed.push("step01");
        this.active = "step02";
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    submitStep2() {
      this.completed = [].concat(...this.completed, ["step02", "step03"]);
      this.active = "step03";
    },
    async submitBankData() {
      this.$refs.bankDataForm.validate(valid => {
        if (valid) {
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.billets-stepper-wrapper {
  padding-top: 5px;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-column-gap: 4px;
}
.billets {
  h3 {
    margin-bottom: 1rem;
  }
  .el-icon {
    color: darken(red, 5);
  }
}
.import {
  .el-button + .el-button {
    margin-left: 8px;
  }
  .el-button {
    border-radius: 3px;
  }
}
.cannot-access {
  text-align: center;
  h2 {
    font-weight: bold;
    color: $laranja;
    text-align: center;
    margin-top: 2rem;
  }
}
</style>
