<template>
  <div class="remittance-and-returns">
    <el-dialog
      :visible.sync="showBilletsDialog">
      <el-table :data="activeBillets">
        <el-table-column
          :formatter="(row, column) => $d(new Date(row.due_date), 'sale_summary')"
          sortable
          prop="due_date"
          label="Venc."
          width="160"/>
        <el-table-column
          :formatter="(row, column) => $n(row.amount, 'currency')"
          sortable
          prop="amount"
          align="right"
          width="120"
          label="Valor"/>
        <el-table-column
          prop="status"
          width="120"
          label="Status"/>
        <el-table-column
          prop="actions"
          align="center"
          label="Ações">
          <template slot-scope="scope">
            <a
              :href="scope.row.url"
              :download="`boleto-neopag-${$d(new Date(scope.row.due_date), 'sale_summary').replace(/\//g, '-')}.pdf`"
              style="text-decoration: none;"
              class="el-button el-button--default el-button--mini">
              <i class="el-icon el-icon-printer"/>
              Baixar
            </a>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-card class="remittance">
      <h2>Controle de arquivos de remessa</h2>
      <el-tabs
        v-model="tabs.remittance">
        <el-tab-pane
          v-loading="loading.remittances"
          name="pending"
          label="Boletos com envio pendente">
          <el-button
            :loading="loading.remittance"
            :disabled="!pendingBillets.length"
            type="primary"
            icon="el-icon-download"
            @click="generateRemittance">
            Gerar arquivo de remessa
          </el-button>
          <el-table
            :data="pendingBillets"
            empty-text="Não existem boletos pendentes">
            <el-table-column
              :formatter="(row, column) => $d(new Date(row.due_date), 'sale_summary')"
              sortable
              prop="due_date"
              label="Venc."
              width="160"/>
            <el-table-column
              :formatter="(row, column) => $n(row.amount, 'currency')"
              sortable
              prop="amount"
              align="right"
              width="120"
              label="Valor"/>
            <el-table-column
              prop="actions"
              align="center"
              label="Ações">
              <template slot-scope="scope">
                <a
                  :href="scope.row.url"
                  :download="`boleto-neopag-${$d(new Date(scope.row.due_date), 'sale_summary').replace(/\//g, '-')}.pdf`"
                  style="text-decoration: none;"
                  class="el-button el-button--default el-button--mini">
                  <i class="el-icon el-icon-printer"/>
                  Baixar
                </a>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          v-loading="loading.remittances"
          name="done"
          label="Arquivos de remessa">
          <el-table
            :data="remittances">
            <el-table-column
              :formatter="(row, column) => $d(new Date(row.sent_on), 'reports')"
              sortable
              prop="sent_on"
              label="Criação" />
            <el-table-column
              :formatter="(row, column) => row.status === 'pending' ? 'Pendente' : row.status"
              prop="status"
              width="140"
              label="Status" />
            <el-table-column
              prop="billets"
              width="140"
              label="Boletos">
              <template slot-scope="scope">
                <el-button
                  :disabled="!scope.row.billets.length"
                  size="mini"
                  @click="handleBilletDetails(scope.row.billets)">Ver ({{ scope.row.billets.length }})</el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="actions"
              width="160"
              align="center"
              label="Ações">
              <template slot-scope="scope">
                <a
                  :href="scope.row.url"
                  :download="`boleto-neopag-${$d(new Date(scope.row.sent_on), 'sale_summary').replace(/\//g, '-')}.pdf`"
                  style="text-decoration: none;"
                  class="el-button el-button--default el-button--mini">
                  <i class="el-icon el-icon-printer"/>
                  Baixar
                </a>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-card class="returns">
      <h2>Controle de retornos</h2>
      <el-tabs
        v-model="tabs.returns">
        <el-tab-pane
          v-loading="loading.returns"
          name="pending"
          label="Boletos com registro/pgto. pendente">
          <el-upload
            ref="upload"
            :auto-upload="false"
            :http-request="handleUpload"
            :before-remove="beforeRemove"
            style="max-width: 301px;"
            action="">
            <el-button
              slot="trigger"
              type="primary">Selecionar arq. de remessa</el-button>
            <el-button
              style="margin-left: 10px;"
              type="success"
              @click="handleSubmit">
              Enviar
            </el-button>
          </el-upload>
          <el-table
            :data="returnsList"
            empty-text="Não existem boletos com registro ou pgto. pendentes">
            <el-table-column
              :formatter="(row, column) => $d(new Date(row.due_date), 'sale_summary')"
              sortable
              prop="due_date"
              label="Venc."
              width="160"/>
            <el-table-column
              :formatter="(row, column) => row.status === 'registered' ? 'Pgto. pendente' : 'Registro pendente'"
              sortable
              prop="status"
              width="140"
              label="Status"/>
            <el-table-column
              :formatter="(row, column) => $n(row.amount, 'currency')"
              sortable
              prop="amount"
              align="right"
              width="120"
              label="Valor"/>
            <el-table-column
              prop="actions"
              align="center"
              label="Ações">
              <template slot-scope="scope">
                <a
                  :href="scope.row.url"
                  :download="`boleto-neopag-${$d(new Date(scope.row.due_date), 'sale_summary').replace(/\//g, '-')}.pdf`"
                  style="text-decoration: none;"
                  class="el-button el-button--default el-button--mini">
                  <i class="el-icon el-icon-printer"/>
                  Baixar
                </a>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          v-loading="loading.returns"
          name="done"
          label="Arquivos de retornos">
          <el-table>
            <el-table-column
              :formatter="(row, column) => $d(new Date(row.due_date), 'sale_summary')"
              sortable
              prop="due_date"
              label="Venc."
              width="160"/>
            <el-table-column
              :formatter="(row, column) => $n(row.amount, 'currency')"
              sortable
              prop="amount"
              align="right"
              width="120"
              label="Valor"/>
            <el-table-column
              prop="actions"
              align="center"
              label="Ações">
              <template slot-scope="scope">
                <a
                  :href="scope.row.url"
                  :download="`boleto-neopag-${$d(new Date(scope.row.due_date), 'sale_summary').replace(/\//g, '-')}.pdf`"
                  style="text-decoration: none;"
                  class="el-button el-button--default el-button--mini">
                  <i class="el-icon el-icon-printer"/>
                  Baixar
                </a>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showBilletsDialog: false,
      activeBillets: [],
      uploading: false,
      loading: {
        remittance: false,
        returns: true
      },
      tabs: {
        remittance: "pending",
        returns: "pending"
      }
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE,
      returns: types.REMITTANCE_RETURNS,
      remittances: types.REMITTANCE_LIST,
      pendingBillets: types.REMITTANCE_PENDING_BILLETS,
      pendingPaymentBillets: types.REMITTANCE_PENDING_PAYMENT_BILLETS,
      pendingRegistrationBillets: types.REMITTANCE_PENDING_REGISTRATION_BILLETS
    }),
    returnsList() {
      return this.pendingPaymentBillets.concat(this.pendingRegistrationBillets);
    }
  },
  watch: {
    async "tabs.remittance"(val) {
      this.loading.remittance = true;
      if (val === "done") {
        await this.$store.dispatch(types.REMITTANCE_LIST, this.storeID)
        .catch((error) => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      } else {
        await this.$store.dispatch(
          types.REMITTANCE_PENDING_BILLETS,
          this.storeID
        );
      }
      this.loading.remittance = false;
    },
    async "tabs.returns"(val) {
      try {
        this.loading.returns = true;
        if (val === "done") {
          await this.$store.dispatch(types.REMITTANCE_RETURNS, this.storeID);
        } else {
          await this.$store.dispatch(
            types.REMITTANCE_PENDING_PAYMENT_BILLETS,
            this.storeID
          );
          await this.$store.dispatch(
            types.REMITTANCE_PENDING_REGISTRATION_BILLETS,
            this.storeID
          );
        }
      } catch(error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading.returns = false;
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(
        types.REMITTANCE_PENDING_PAYMENT_BILLETS,
        this.storeID
      );
      await this.$store.dispatch(
        types.REMITTANCE_PENDING_REGISTRATION_BILLETS,
        this.storeID
      );
      this.loading.returns = false;
    } catch(error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    generateRemittance() {
      this.loading.remittance = true;
      this.$store
        .dispatch(types.REMITTANCE_CREATE, this.storeID)
        .catch((error) => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
          this.loading.remittance = false;
        })
        .then(response => {
          this.currentRemittance = response.data.url;
          this.loading.remittance = false;
        });
    },
    handleBilletDetails(billets) {
      this.activeBillets = billets;
      this.showBilletsDialog = true;
    },
    downloadBillet(url) {
      window.open(url, `_blank`);
    },
    downloadRemittance() {
      window.open(this.currentRemittance, `_blank`);
    },
    beforeRemove(file) {
      return this.$confirm(`Remover ${file.name}？`);
    },
    handleSubmit() {
      this.$refs.upload.submit();
    },
    async handleUpload(upload) {
      try {
        const file = upload.file;
        const payload = new FormData();
        payload.append("file", file);

        this.uploading = true;
        await this.$store.dispatch(types.REMITTANCE_UPLOAD, {
          storeID: this.storeID,
          payload
        });
        this.$notify.success({
          message: "Arquivo enviado com sucesso!"
        });
        this.$refs.upload.clearFiles();
        this.uploading = false;
      } catch(error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.remittance-and-returns {
  .remittance,
  .returns {
    max-width: 90%;
    margin: 2rem auto 0;

    @include large-up {
      max-width: 800px;
    }
  }
}
</style>
