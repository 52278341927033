<template>
  <section class="step01--wrapper">
    <div class="custom-divider"></div>

    <el-row>
      <el-col :lg="24">
        <el-card>
          <div class="card-text--wrapper">
            <h4>Ativar emissão de boletos</h4>
          </div>
          <div class="card-content--wrapper">
            <el-switch
              v-model="billets"
              active-text="Ativar"
              inactive-text="Desativar"
              size="small"
            />
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="custom-divider"></div>
    <el-row>
      <el-col :lg="24">
        <el-card>
          <div class="card-text--wrapper">
            <h4>
              Permitir emissão de boletos para todos os seus clientes
              <i
                v-popover:mass
                :tabindex="-1"
                class="el-icon el-icon-warning"
              />
            </h4>
            <el-popover
              ref="mass"
              trigger="hover"
              placement="bottom"
              width="320"
              content="Você perderá todas as suas configurações personalizadas"
            />
          </div>
          <div class="card-content--wrapper">
            <el-switch
              v-model="mass_billets"
              active-text="Ativar"
              inactive-text="Desativar"
              size="small"
            />
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="custom-divider"></div>

    <el-row>
      <el-col>
        <div class="button-container">
          <el-button
            :disabled="!(billets && mass_billets)"
            type="primary"
            size="medium"
            @click="handleNext"
          >Próximo</el-button>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      billets: false,
      mass_billets: false
    };
  },
  methods: {
    handleNext() {
      this.$emit("onNext");
    }
  }
};
</script>

<style lang="scss" scoped>
.step01--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-content--wrapper {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-text--wrapper {
  text-align: center;
}
.custom-divider {
  margin: 0.5rem 0;
}
</style>
