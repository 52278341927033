<template>
  <div class="pagamento">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/configuracoes/assinatura' }">Assinatura e pagamento</el-breadcrumb-item>
      <el-breadcrumb-item>Meio de pagamento</el-breadcrumb-item>
    </el-breadcrumb>
    <h1>Pagamento</h1>
    <div class="escolher-meio">
      <h2>Escolha o meio de pagamento</h2>
      <el-radio v-model="paymentMethod" label="boleto" border>Boleto por email</el-radio>
      <small>Ativação em até 2 dias úteis</small>
      <el-radio v-model="paymentMethod" label="cartao" border>Cartão de crédito online</el-radio>
      <small>Ativação imediata</small>
      <h2 v-if="activePaymentMethod">Meio atual</h2>
      <div v-if="activePaymentMethod" class="resumo-payment">
        <div :class="activePaymentMethod.data.brand.toLowerCase()" class="flag" />
        <div class="numbers">
          <div
            class="card"
          >{{ activePaymentMethod.data.brand | capitalize }}{{ activePaymentMethod.data.display_number.replace('XXXX-XXXX-XXXX-', ' **** ') }}</div>
          <div
            class="due"
          >Vencimento: {{ activePaymentMethod.data.month }}/{{ activePaymentMethod.data.year }}</div>
        </div>
      </div>
    </div>
    <div v-if="paymentMethod !== undefined" class="dados-do-meio">
      <div class="meu-plano">
        <div class="meu-plano__resumo">
          <h2
            :style="{ backgroundColor: activePlan.color }"
            class="plan-title"
          >{{ activePlan.mini_label }}</h2>
          <h2 class="plan-price">
            {{ planPrice }}
            <small>/ mês</small>
          </h2>
          <p v-if="+activePlan.price">
            Seu plano será renovado automaticamente todo
            <br />dia
            <b>{{ renewalDate }}</b> e serão cobrados
            <b>{{ planPrice }}</b>
          </p>
          <p v-if="paymentMethod === 'boleto'">
            Enviaremos o boleto para
            <b>{{ storeDetails.email }}</b>
          </p>
        </div>
        <div v-if="paymentMethod === 'boleto'">
          <br />
          <el-button :loading="submitting" type="success" @click="toPayslip">Confirmar</el-button>
        </div>
      </div>
      <div v-if="paymentMethod === 'cartao'">
        <p class="disclaimer">
          A Neopag emitirá uma autorização temporária no seu cartão como confirmação. Trata-se apenas de uma autorização e NÃO uma cobrança. Talvez seu banco o informe sobre a autorização.
          <br />
          <br />Pelo presente, você autoriza a Neopag a cobrar de você todo mês automaticamente até que você cancele sua inscrição.
        </p>
        <el-form
          ref="cardForm"
          :model="cardForm"
          :rules="cardFormRules"
          class="card-form"
          @submit.prevent.native="e => e.preventDefault()"
        >
          <el-row :gutter="20">
            <el-col :sm="14">
              <el-form-item prop="number">
                <i :class="currentFlag" class="number" />
                <el-input
                  v-mask="'#### #### #### ####'"
                  v-model="cardForm.number"
                  class="credit_card_number"
                  name="cardnumber"
                  autocomplete.native="cc-number"
                  data-iugu="number"
                  placeholder="Número do cartão"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="10">
              <el-form-item prop="verification_value">
                <i class="verification_value" />
                <el-input
                  v-mask="'###'"
                  v-model="cardForm.verification_value"
                  name="cvc"
                  autocomplete.native="cc-csc"
                  class="credit_card_cvv"
                  data-iugu="verification_value"
                  placeholder="CVV"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="14">
              <el-form-item prop="full_name">
                <i class="full_name" />
                <el-input
                  v-model="cardForm.full_name"
                  class="credit_card_name"
                  name="cc-name"
                  autocomplete.native="cc-name"
                  data-iugu="full_name"
                  placeholder="Titular do cartão"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="10">
              <el-form-item prop="expiration">
                <i class="expiration" />
                <el-input
                  v-mask="'##/##'"
                  v-model="cardForm.expiration"
                  class="credit_card_expiration"
                  name="cc-exp"
                  autocomplete.native="cc-exp"
                  data-iugu="expiration"
                  placeholder="MM/AA"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-button
            v-loading="submitting"
            class="submit-card"
            type="success"
            @click="submitCard"
          >Salvar Cartão</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import capitalize from "lodash/capitalize";
import moment from "moment";

export default {
  filters: {
    capitalize: val => capitalize(val)
  },
  data() {
    const validateCVV = (rule, value, cb) => {
      const val = value.toString();
      if ((val === "") & !val) {
        return cb(new Error("Digite o CVV"));
      }
      if (val && val.length !== 3) {
        return cb(new Error("CVV têm 3 caractéres"));
      }
      const isValid = Iugu.utils.validateCVV(val, this.currentFlag);
      if (!isValid) {
        return cb(new Error("CVV Inválido"));
      }
      return cb();
    };
    const validateNumber = (rule, value, cb) => {
      this.currentFlag = "";
      if ((value === "") & !value) {
        return cb(new Error("Digite o # do cartão"));
      }
      if (!Iugu.utils.validateCreditCardNumber(value)) {
        return cb(new Error("# do cartão inválido"));
      } else {
        this.currentFlag = Iugu.utils.getBrandByCreditCardNumber(value);
      }
      return cb();
    };
    const validateExpiration = (rule, value, cb) => {
      if ((value === "") & !value) {
        return cb(new Error("Digite data de expiração"));
      }
      if (!Iugu.utils.validateExpirationString(value)) {
        return cb(new Error("Data de expiração inválida"));
      }
      cb();
    };
    const validateFullname = (rule, value, cb) => {
      if ((value === "") & !value) {
        return cb(new Error("Digite o nome completo"));
      }
      if (value.trim().split(" ").length <= 1) {
        return cb(new Error("Digite o nome e o sobrenome"));
      }
      cb();
    };
    return {
      cardFormRules: {
        verification_value: [{ validator: validateCVV, trigger: "blur" }],
        number: [{ validator: validateNumber, trigger: "blur" }],
        expiration: [{ validator: validateExpiration, trigger: "blur" }],
        full_name: [{ validator: validateFullname, trigger: "blur" }]
      },
      cardForm: {
        description: "Pagamento via cartão",
        number: "",
        verification_value: "",
        full_name: "",
        expiration: ""
      },
      paymentMethod: undefined,
      submitting: false,
      currentFlag: "",
      activePlanIndex: 0,
      activePlan: {
        color: "",
        start: undefined,
        name: "",
        price: 0
      }
    };
  },
  computed: {
    ...mapGetters({
      plans: types.SUBSCRIPTION_PLANS,
      currentPlan: types.SUBSCRIPTION_DETAILS,
      storeID: types.AUTH_ACTIVE_STORE,
      storeDetails: types.AUTH_ACTIVE_STORE_DETAILS,
      paymentMethods: types.SUBSCRIPTION_PAYMENT_METHODS
    }),
    activePaymentMethod() {
      return this.paymentMethods[this.paymentMethods.length - 1];
    },
    renewalDate() {
      if (this.currentPlan.due_day) {
        return this.currentPlan.due_day;
      }
      return moment().format("DD");
    },
    planPrice() {
      return this.$n(this.activePlan.price, "currency");
    }
  },
  mounted() {
    const id = this.$route.params.planID;
    this.plans.forEach((it, index) => {
      if (it.iugu_identifier === id) {
        this.activePlanIndex = index;
        this.activePlan = it;
      }
    });
  },
  methods: {
    async updateSubscription(paymentId) {
      const planID = this.activePlan.id;
      await this.$store.dispatch(types.SUBSCRIPTION_UPDATE, {
        storeID: this.storeID,
        planID,
        paymentId
      });
      return;
    },
    submitCard() {
      this.$refs.cardForm.validate(valid => {
        if (valid) {
          this.submitting = true;
          const $form = this.$refs.cardForm.$el;
          Iugu.createPaymentToken($form, this.tokenResponseHandler);
        }
      });
    },
    async toPayslip() {
      try {
        this.submitting = true;
        await this.$store.dispatch(types.SUBSCRIPTION_PAYMENT_METHODS_UPDATE, {
          storeID: this.storeID,
          default_payment_method_id: null
        });
        // await this.updateSubscription();
        this.$store.dispatch(types.SUBSCRIPTION_DETAILS, this.storeID);
        this.submitting = false;
        setTimeout(() => {
          this.$router.push({
            name: "Configurações - Assinatura e pagamento"
          });
        }, 200);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async tokenResponseHandler(data) {
      try {
        const token = data.id;
        if (!token) {
          this.$notify.error({
            title: "Não foi possível vincular este cartão, tente de novo!"
          });
          this.submitting = false;
          return false;
        }
        const response = await this.$store.dispatch(
          types.SUBSCRIPTION_PAYMENT_METHODS_CREATE,
          {
            store_id: this.storeID,
            description: this.cardForm.description,
            token,
            set_as_default: true
          }
        );
        if (response && response.status === 200) {
          this.$notify.success({
            title: "Cartão vinculado!"
          });
        } else {
          this.$notify.error({
            title: "Não foi possível vincular este cartão, tente de novo!"
          });
        }
        await this.updateSubscription(response.data.id);
        this.submitting = false;
        setTimeout(() => {
          this.$router.push({
            name: "Configurações - Assinatura e pagamento"
          });
        }, 200);
      } catch (error) {
        this.submitting = false;
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>

<style lang="scss">
.pagamento {
  display: flex;
  flex-flow: row wrap;

  h1 {
    flex: 0 100%;
  }
  > .escolher-meio {
    align-self: flex-start;
    flex: 0 280px;
  }
  > .dados-do-meio {
    flex: 1;
    padding-left: 1rem;
  }
  .resumo-payment {
    display: flex;
    flex-flow: row wrap;
    background: rgba($preto, 0.125);
    border: 1px solid $preto;
    padding: 1rem;
    max-width: 385px;
    margin: 0 auto 1rem;
    .flag {
      margin-right: 10px;
      flex: 0 30px;
      background-size: 100% 100%;
      width: 30px;
      height: 20px;
      z-index: 2;
      transition: background-position 0.2s ease-in;
      background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-brands.48dba03883007f86e118f683dcfc4297.png")
        no-repeat;

      &.visa {
        background-position: 0px -22px;
      }
      &.mastercard {
        background-position: 0px -66px;
      }
    }
    .numbers {
      flex: 1;
      text-align: left;
      .card {
        font-family: $title-font;
        color: #000;
        line-height: 1.5;
        letter-spacing: 1px;
      }
      .due {
        font-family: $title-font;
        font-size: 12px;
        line-height: 1.5;
        font-weight: 500;
        color: $preto;
        letter-spacing: 1px;
      }
    }
  }
  .escolher-meio {
    max-width: 385px;
    width: 100%;
    text-align: center;
    border: 1px solid $laranja;
    padding: 1rem;
    border-radius: 8px;
    margin: 0 auto;

    @include medium-down {
      margin-bottom: 1rem;
    }

    h2 {
      margin-bottom: 1rem;
      line-height: 1.45;
    }
    small {
      position: relative;
      font-family: $title-font;
      top: -14px;
      color: $preto;
    }
    .el-radio {
      width: 100%;
      display: block;
      margin-bottom: 1rem;
      height: 60px;
      line-height: 33px;
      white-space: wrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &__label {
        font-size: 18px;
        font-family: $title-font;
        position: relative;
        top: 2px;
      }

      + .el-radio {
        margin-left: 0;
      }
    }
  }
  .meu-plano {
    margin: 0 auto;
    text-align: center;
    font-family: $title-font;
  }
  .plan-title {
    width: fit-content;
    margin: 0 auto 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 4px 1rem;
    border-radius: 12px;
    color: #fff;
  }
  .plan-price {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .submit-card {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
}

.disclaimer {
  margin: 1rem auto;
  font-size: 14px;
  line-height: 1.5;
  max-width: 400px;
  color: $preto;
  opacity: 0.75;
}

.card-form {
  max-width: 400px;
  margin: 2rem auto;
  .el-input {
    // margin-left: 40px;
    input {
      padding-left: 38px;
    }
  }
  .el-col {
    position: relative;
  }
  i {
    display: inline-block;
    background-size: 100% 100%;
    position: absolute;
    width: 22px;
    height: 22px;
    background: #ccc;
    left: 8px;
    top: 7px;
    z-index: 2;
    transition: background-position 0.2s ease-in;
  }
  input {
    padding-left: 38px;
  }
  i.number {
    background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-brands.48dba03883007f86e118f683dcfc4297.png")
      no-repeat;

    &.visa {
      background-position: 0px -22px;
    }
    &.mastercard {
      background-position: 0px -66px;
    }
  }
  i.verification_value {
    background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-cvv.1fe78dcc390427094bdc14dedea10f34.png")
      no-repeat;
  }
  i.full_name {
    background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-name.1cafa1882fdd56f8425de54a5a5bbd1e.png")
      no-repeat;
  }
  i.expiration {
    background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-exp.05e708b1489d5e00c871f20ba33bbff3.png")
      no-repeat;
  }
}
</style>
