<template>
  <section>
    <div class="custom--divider"></div>
    <el-row type="flex" justify="center">
      <div
        v-if="account.number && isIuguConfigured && store.create_billets"
        class="withdraw--wrapper"
      >
        <div class="custom--text">
          <h2>
            Saldo disponível:
            <strong
              style="font-weight: 600; font-size: 2rem; color: #000;"
            >{{ billetsBalances.balance_available_for_withdraw || 'R$ 0,00' }}</strong>
          </h2>
        </div>
        <div class="custom--divider"></div>
        <el-button
          :disabled="!billetsBalances.balance_available_for_withdraw"
          type="danger"
          round
          @click="showWithdrawalModal = true"
        >Solicitar saque</el-button>
        <el-button
          :disabled="!billetsBalances.balance_available_for_withdraw"
          type="danger"
          round
          @click="showHistoryModal = true"
        >Histórico de saques</el-button>
      </div>
    </el-row>
    <div class="custom--divider"></div>

    <el-row>
      <div class="account-info--wrapper" v-if="account.number">
        <el-card>
          <div slot="header" class="header--card">
            <h2 class="header--title">Titularidade da conta</h2>
            <el-button
              @click="editOwnershipInfo = true"
              v-if="store.iugu_account_status !== 'pending'"
              :disabled="true"
            >Solicitar alteração</el-button>
          </div>
          <div class="card--wrapper">
            <p v-if="isIuguConfigured">
              <strong>Nome do Responsável:</strong>
              {{ account.owner_name }}
            </p>
            <p v-if="isIuguConfigured">
              <strong>CPF do Responsável:</strong>
              {{ account.owner_cpf }}
            </p>
            <p>
              <strong>Tipo de Titularidade:</strong>
              {{account.holder_type === 'pj' ? 'Pessoa-Jurídica' : 'Pessoa-Física'}}
            </p>
          </div>
        </el-card>
        <el-card>
          <div slot="header" class="header--card">
            <h2 class="header--title">Dados para depósito</h2>
            <el-button
              v-if="store.iugu_account_status !== 'pending'"
              @click="editDataForDepositInfo = true"
            >Solicitar alteração</el-button>
          </div>
          <div class="card--wrapper">
            <p>
              <strong>Banco:</strong>
              {{ account.bank ? account.bank.name : '' }}
            </p>
            <p>
              <strong>Agência:</strong>
              {{ account.branch }}{{ account.branch_digit ? ` - ${account.branch_digit}` : '' }}
            </p>
            <p>
              <strong>Conta:</strong>
              {{ account.number }}{{ account.digit ? ` - ${account.digit}` : '' }}
            </p>
            <p>
              <strong>Tipo da Conta:</strong>
              {{ account.account_type === 'checking' ? 'Corrente' : 'Poupança' }}
            </p>
          </div>
        </el-card>
      </div>
    </el-row>
    <el-dialog
      title="Formulário dos Dados para depósito"
      :visible.sync="editDataForDepositInfo"
      @close="editDataForDepositInfo = false"
      :fullscreen="$isMobile ? true : false"
    >
      <data-for-deposit-bank-form />
    </el-dialog>
    <el-dialog
      title="Formulário de Titularidade"
      :visible.sync="editOwnershipInfo"
      @close="editOwnershipInfo = false"
      :fullscreen="$isMobile ? true : false"
    >
      <ownership-bank-form />
    </el-dialog>
    <el-dialog :visible.sync="showWithdrawalModal" title="Saque">
      <div class="el-input">
        <money
          ref="input"
          v-model="withdrawalInputValue"
          v-bind="$money"
          :disabled="loading"
          class="el-input__inner"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :disabled="loading" round @click="showWithdrawalModal = false">Cancelar</el-button>
        <el-button :loading="loading" type="primary" round @click="submitWithdrawal">Confirmar</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showHistoryModal" title="Histórico de saques">

      <el-table :data="withdrawals">
        <el-table-column
          :formatter="row => formatDate(row.date)"
          prop="date"
          label="Data"
        />
        <el-table-column
          :formatter="row => formatMoney(row.amount)"
          prop="amount"
          label="Valor"
        />
        <el-table-column
          prop="status"
          label="Status"
        />
        <el-table-column
          prop="feedback"
          label="Motivo"
        />

      </el-table>

    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import { Money } from "v-money";
import DataForDepositBankForm from "@/components/Settings/Billets/DataForDepositBankForm";
import OwnershipBankForm from "@/components/Settings/Billets/OwnershipBankForm";
import moment from "moment";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle, faCheck, faSearch, faBan, faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faExclamationTriangle,
  faCheck,
  faSearch,
  faBan,
  faCommentSlash,
);
export default {
  components: {
    Money,
    DataForDepositBankForm,
    OwnershipBankForm,
    FontAwesomeIcon
  },
  filters: {
    toMoney: amount =>
      new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(parseFloat(amount)),
    toDate: date => moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
  },
  props: {
    load: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      withdrawalInputValue: "",
      showHistoryModal: false,
      showWithdrawalModal: false,
      activeBilletColapse: "",
      editDataForDepositInfo: false,
      editOwnershipInfo: false,
      loading: this.load
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE,
      account: types.STORE_BANK_ACCOUNT,
      billetsBalances: types.STORE_BILLETS_BALANCES,
      store: types.STORE_INFO,
      withdrawals: types.STORE_WITHDRAWALS
    }),
    isIuguConfigured() {
      if (this.store.billet_config === "iugu") {
        return true;
      }
      return false;
    }
  },
  async mounted() {
    this.$emit("loading", true);
    try {
      await this.$store.dispatch(
        types.STORE_BANK_ACCOUNT_DETAILS,
        this.storeID
      );
      await this.$store.dispatch(
        types.STORE_GET_BILLETS_BALANCES,
        this.storeID
      );
      await this.$store.dispatch(types.STORE_WITHDRAWAL_LIST, this.storeID);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.$emit("loading", false);
  },
  methods: {
    async submitWithdrawal() {
      try {
        this.$emit("loading", true);
        await this.$store.dispatch(types.STORE_REQUEST_WITHDRAWAL, {
          amount: this.withdrawalInputValue,
          store_id: this.storeID
        });
        this.$notify.success({
          message: "Saque realizado com sucesso!"
        });
        await this.$store.dispatch(types.STORE_BANKS);
        await this.$store.dispatch(
          types.STORE_BANK_ACCOUNT_DETAILS,
          this.storeID
        );
        await this.$store.dispatch(types.STORE_WITHDRAWAL_LIST, this.storeID);
        await this.$store.dispatch(
          types.STORE_GET_BILLETS_BALANCES,
          this.storeID
        );
        this.showWithdrawalModal = false;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.$emit("loading", false);
    },
    copyToClipboard(barcode) {
      navigator.permissions
        .query({ name: "clipboard-write" })
        .then(async result => {
          if (result.state == "granted" || result.state == "prompt") {
            navigator.clipboard
              .writeText(barcode)
              .then(() =>
                this.$notify({
                  title: "Copiado!",
                  message: "Código copiado com sucesso",
                  type: "success"
                })
              )
              .catch(() =>
                this.$notify.error({
                  title: "Erro!",
                  message: "Não foi possivel copiar o código de barras"
                })
              );
          }
        });
    },
    async handleLink(link) {
      window.open(link, "_blank");
    },
    formatMoney(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(parseFloat(amount));
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    handleStatusLabel(status) {
      const labels = {
        pending: "Pendente",
        pending_registration: "Registro pendente",
        registered: "Registrado",
        paid: "Pago",
        canceled: "Cancelado",
        partially_paid: "Pago parcialmente",
        refunded: "Reembolsado",
        expired: "Expirado"
      };
      return labels[status];
    }
  }
};
</script>

<style lang="scss" scoped>
.account-info--wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .el-card {
    flex-grow: 1;
    margin: 1rem;
    .header--card {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .header--title {
        font-weight: bold;
        color: $laranja;
      }
    }
    .card--wrapper {
      p {
        line-height: 2rem;
      }
    }
  }
}
.custom--divider {
  margin: 0.5rem 0;
}
.custom--text {
  text-align: center;
}

.withdrawal-header {
  width: 100%;
  max-width: 579px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.withdrawal {
  .account {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    > p {
      margin: 0 16px 0 0;
      vertical-align: middle;
    }
  }
}

</style>
