<template>
  <div class="pgc">
    <h2 v-if="!config">
      Escolha um perfil para iniciar a sua
      <b>gestão de crédito</b>!
    </h2>
    <el-select :size="$isMobile ? 'small' : '' " v-model="selectedOption" style="width: 140px;">
      <el-option v-for="option in options" :key="option" :label="option" :value="option" />
    </el-select>
    <el-button
      :size="$isMobile ? 'small' : '' "
      :loading="loading"
      style="margin-left: 16px; text-transform: uppercase;"
      type="primary"
      @click="saveConfigurations"
    >salvar configurações</el-button>
    <el-button
      v-if="!config"
      :size="$isMobile ? 'small' : '' "
      style="margin-left: 16px; text-transform: uppercase;"
      type="secondary"
      @click="handleCustomPlans"
    >personalizar planos</el-button>
    <div class="cards">
      <el-card class="payment">
        <div slot="header">
          <h4>Recebimentos</h4>
        </div>
        <p>
          Prazo p/ atrasos:
          <b>{{ active.overdue.days }}</b>
        </p>
        <p>
          Juros por atraso:
          <b>{{ active.overdue.interest }}%</b>
        </p>
      </el-card>
      <el-card class="sale">
        <div slot="header">
          <h4>Vendas</h4>
        </div>
        <p>
          Dias de venc. para os clientes:
          <b>{{ active.due_days.join('-') }}</b>
        </p>
        <p>
          Dias para fechamento da fatura:
          <b>{{ active.cutoff_days }}</b>
        </p>
        <el-table :data="active.salePlans" size="mini">
          <el-table-column prop="name" label="Plano" />
          <el-table-column
            :formatter="(row) => [row.installment.from, row.installment.to].join(' até ')"
            align="center"
            width="55"
            label="Parcelas"
          />
          <el-table-column
            :formatter="(row) => row.interest + '%'"
            align="right"
            width="45"
            label="Juros"
          />
          <el-table-column
            :formatter="(row) => row.down_payment + '%'"
            width="55"
            align="right"
            label="Entrada"
          />
          <el-table-column
            :formatter="(row) => row.grace_period"
            align="right"
            width="60"
            label="À prazo"
          />
        </el-table>
      </el-card>
      <el-card class="deal">
        <div slot="header">
          <h4>Reparcelamentos</h4>
        </div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <el-table :data="active.dealPlans" size="mini">
          <el-table-column prop="name" label="Plano" width="160" />
          <el-table-column
            :formatter="(row) => [row.installment.from, row.installment.to].join(' até ')"
            align="center"
            width="53"
            label="Parcelas"
          />
          <el-table-column
            :formatter="(row) => row.interest + '%'"
            align="right"
            width="42"
            label="Juros"
          />
          <el-table-column
            :formatter="(row) => row.down_payment + '%'"
            width="52"
            align="right"
            label="Entrada"
          />
          <el-table-column
            :formatter="(row) => row.grace_period"
            align="right"
            width="58"
            label="À prazo"
          />
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  props: {
    config: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      selectedOption: "Moderado",
      options: ["Conservador", "Moderado", "Arrojado"],
      templates: {
        Conservador: {
          due_days: [5, 10, 15, 20, 25, 28],
          cutoff_days: 10,
          overdue: {
            interest: 3,
            days: 7
          },
          salePlans: [
            {
              name: "Plano sem juros",
              installment: {
                from: 1,
                to: 4
              },
              interest: 0,
              down_payment: 0,
              grace_period: 0
            },
            {
              name: "Plano com juros",
              installment: {
                from: 5,
                to: 6
              },
              interest: 2,
              down_payment: 0,
              grace_period: 0
            }
          ],
          dealPlans: [
            {
              name: "Plano de reparcelamento",
              installment: {
                from: 1,
                to: 4
              },
              interest: 0,
              down_payment: 0,
              grace_period: 0
            },
            {
              name: "Plano de acordos",
              installment: {
                from: 1,
                to: 5
              },
              interest: 5,
              down_payment: 10,
              grace_period: 0
            }
          ]
        },
        Moderado: {
          due_days: [5, 10, 15, 20, 25, 30],
          cutoff_days: 10,
          overdue: {
            interest: 5,
            days: 5
          },
          salePlans: [
            {
              name: "Plano sem juros",
              installment: {
                from: 1,
                to: 3
              },
              interest: 0,
              down_payment: 0,
              grace_period: 0
            },
            {
              name: "Plano com juros",
              installment: {
                from: 4,
                to: 6
              },
              interest: 3,
              down_payment: 0,
              grace_period: 0
            }
          ],
          dealPlans: [
            {
              name: "Plano de reparcelamento",
              installment: {
                from: 1,
                to: 3
              },
              interest: 0,
              down_payment: 0,
              grace_period: 0
            },
            {
              name: "Plano de acordos",
              installment: {
                from: 1,
                to: 5
              },
              interest: 8,
              down_payment: 10,
              grace_period: 0
            }
          ]
        },
        Arrojado: {
          due_days: [5, 10, 15, 20, 25, 30],
          cutoff_days: 10,
          overdue: {
            interest: 8,
            days: 3
          },
          salePlans: [
            {
              name: "Plano sem juros",
              installment: {
                from: 1,
                to: 3
              },
              interest: 0,
              down_payment: 0,
              grace_period: 0
            },
            {
              name: "Plano com juros",
              installment: {
                from: 4,
                to: 8
              },
              interest: 5,
              down_payment: 0,
              grace_period: 0
            }
          ],
          dealPlans: [
            {
              name: "Plano de reparcelamento",
              installment: {
                from: 1,
                to: 3
              },
              interest: 0,
              down_payment: 0,
              grace_period: 0
            },
            {
              name: "Plano de acordos",
              installment: {
                from: 1,
                to: 8
              },
              interest: 10,
              down_payment: 15,
              grace_period: 0
            }
          ]
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE,
      plans: types.STORE_PLANS,
      dealPlans: types.STORE_DEAL_PLANS
    }),
    active() {
      return this.templates[this.selectedOption];
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.STORE_PLANS, this.storeID);
      await this.$store.dispatch(types.STORE_DEAL_PLANS, this.storeID);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    async saveConfigurations() {
      this.loading = true;
      try {
        await this.$store.dispatch(types.STORE_CONFIG_UPDATE, {
          storeID: this.storeID,
          payload: {
            configs: {
              cutoff_days: this.active.cutoff_days,
              overdue: this.active.overdue,
              due_days: this.active.due_days
            }
          }
        });
        /*
        if (this.plans.length) {
          this.plans.forEach(async plan => {
            await this.$store.dispatch(types.STORE_PLANS_DELETE, {
              storeID: this.storeID,
              planID: plan.id
            });
          });
        }
        */
        this.active.salePlans.forEach(async plan => {
          await this.$store.dispatch(types.STORE_PLANS_CREATE, {
            storeID: this.storeID,
            planID: undefined,
            payload: {
              plan
            }
          });
        });
        /*
        if (this.dealPlans.length) {
          this.dealPlans.forEach(async plan => {
            await this.$store.dispatch(types.STORE_DEAL_PLANS_DELETE, {
              storeID: this.storeID,
              planID: plan.id
            });
          });
        }
        */
        this.active.dealPlans.forEach(async plan => {
          await this.$store.dispatch(types.STORE_DEAL_PLANS_CREATE, {
            storeID: this.storeID,
            planID: undefined,
            payload: {
              plan
            }
          });
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    },
    handleCustomPlans() {
      this.$router.push({
        name: "Configurações - Planos"
      });
    }
  }
};
</script>

<style lang="scss">
.pgc {
  .el-select {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  button {
    span {
      color: inherit;
    }
  }
  h2 {
    line-height: 1.5;
    b {
      font-weight: 600;
    }
  }
  @include medium-down {
    h2 {
      font-size: 13px;
    }
  }
  .cards {
    display: flex;
    flex-flow: row wrap;
    font-family: $title-font;
    font-size: 14px;
    p {
      color: $preto;
      line-height: 1.55;
    }
    b {
      float: right;
    }
    .el-table {
      margin-top: 1rem;
    }
    .el-table .cell {
      padding: 0;
    }
    .el-table th {
      padding: 0;
      font-size: 12px;
      line-height: 1;
      vertical-align: bottom;
    }
  }
  .payment {
    flex: 1;
    margin-right: 2.5%;
  }
  .sale {
    flex: 2;
    margin-right: 2.5%;
    .el-table {
    }
  }
  .deal {
    flex: 2;
    .el-table {
      min-width: 420px;
    }
  }
}
</style>
