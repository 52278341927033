<template>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
			<el-card>
				<div slot="header">
					<h1 class="custom-header">Informações básicas</h1>
				</div>
				<div class="informacoes-empresa">
					<image-upload
						v-model="showAvatarUploader"
						:width="80"
						:height="80"
						lang-type="pt-br"
						img-format="png"
						@crop-success="cropSuccess"
					/>
					<header class="informacoes-empresa__header">
						<div class="avatar">
							<img
								:src="newAvatarDataURL || info.logo || $avatar"
								class="avatar__preview"
								width="80"
								height="80"
							/>
							<br />
							<div class="avatar__overlay">
								<el-button
									class="avatar__change"
									type="primary"
									size="mini"
									@click="showAvatarUploader = !showAvatarUploader"
								>Trocar</el-button>
							</div>
						</div>
						<div class="info">
							<div class="info__row">
								<b class="info__label">Nome:</b>
								<div v-if="inputs.name" class="info__input">
									<el-input v-model="tmpValues.name" size="mini" />
									<el-button
										:loading="loading"
										type="success"
										size="mini"
										icon="el-icon-success"
										@click="updateInfo({
								name: tmpValues.name
						}, 'name')"
									/>
									<el-button
										:loading="loading"
										type="danger"
										size="mini"
										icon="el-icon-error"
										@click="closeInput('name')"
									/>
								</div>
								<div v-else class="info__display">
									<span class="info-text">{{ info.name }}</span>
									<el-button
										type="text"
										size="small"
										icon="el-icon-edit"
										@click="showInput({ name: 'name', defaultValue: info.name })"
									/>
								</div>
							</div>
							<div class="info__row">
								<b class="info__label">Email:</b>
								<div v-if="inputs.email" class="info__input">
									<el-input v-model="tmpValues.email" size="mini" />
									<el-button
										:loading="loading"
										type="success"
										size="mini"
										icon="el-icon-success"
										@click="updateInfo({
								email: tmpValues.email
						}, 'email')"
									/>
									<el-button
										:loading="loading"
										type="danger"
										size="mini"
										icon="el-icon-error"
										@click="closeInput('email')"
									/>
								</div>
								<div v-else class="info__display">
									<span class="info-text">{{ info.email }}</span>
									<el-button
										type="text"
										size="small"
										icon="el-icon-edit"
										@click="showInput({ name: 'email', defaultValue: info.email })"
									/>
								</div>
							</div>
							<div class="info__row">
								<b class="info__label">Descrição:</b>
								<div v-if="inputs.description" class="info__input">
									<el-input
										:rows="2"
										v-model="tmpValues.description"
										style="margin-bottom: 0.5rem;"
										type="textarea"
										size="mini"
									/>
									<el-button
										:loading="loading"
										style="margin-bottom: 0.5rem;"
										type="success"
										size="mini"
										icon="el-icon-success"
										@click="updateInfo({
								description: tmpValues.description
						}, 'description')"
									/>
									<el-button
										:loading="loading"
										style="margin-bottom: 0.5rem;"
										type="danger"
										size="mini"
										icon="el-icon-error"
										@click="closeInput('description')"
									/>
								</div>
								<div v-else class="info__display">
									<span class="info-text">{{ info.description || 'Sem descrição' }}</span>
									<el-button
										type="text"
										size="small"
										icon="el-icon-edit"
										@click="showInput({ name: 'description', defaultValue: info.description })"
									/>
								</div>
							</div>
							<div class="info__row">
								<b class="info__label">Telefone:</b>
								<div v-if="inputs.phone" class="info__input">
									<el-input v-mask="'(##) #####-####'" v-model="tmpValues.phone" size="mini" />
									<el-button
										:loading="loading"
										type="success"
										size="mini"
										icon="el-icon-success"
										@click="updateInfo({
								phone: formatPhone(tmpValues.phone)
						}, 'phone')"
									/>
									<el-button
										:loading="loading"
										type="danger"
										size="mini"
										icon="el-icon-error"
										@click="closeInput('phone')"
									/>
								</div>
								<div v-else class="info__display">
									<span
										class="info-text"
									>{{ info.phone ? info.phone.replace('+55', '') : '' | toPhone }}</span>
									<el-button
										type="text"
										size="small"
										icon="el-icon-edit"
										@click="showInput({ name: 'phone', defaultValue: info.phone ? info.phone.replace('+55', '') : '' })"
									/>
								</div>
							</div>
							<div class="info__row">
								<b class="info__label">CNPJ:</b>
								<div class="info__display">
									<span class="info-text">{{ info.cnpj | toCNPJ }}</span>
								</div>
							</div>
							<div class="info__row">
								<b class="info__label">Endereço:</b>
								<div v-if="inputs.address" class="info__input">
									<el-form label-width="120px">
										<el-form-item label="CEP">
											<el-input
												v-mask="'########'"
												v-model="newAddress.cep"
												size="mini"
												@input.native="handleCEPInput"
											/>
										</el-form-item>
										<el-form-item label="Logradouro">
											<el-input
												:disabled="autofill.street"
												v-model="newAddress.street"
												size="mini"
											/>
										</el-form-item>
										<el-form-item label="Número">
											<el-input v-model="newAddress.number" size="mini" />
										</el-form-item>
										<el-form-item label="Complemento">
											<el-input v-model="newAddress.complement" size="mini" />
										</el-form-item>
										<el-form-item label="Cidade">
											<el-input :disabled="autofill.city" v-model="newAddress.city" size="mini" />
										</el-form-item>
										<el-form-item label="Estado">
											<el-input :disabled="autofill.uf" v-model="newAddress.uf" size="mini" />
										</el-form-item>
										<el-form-item>
											<el-button
												:loading="loading"
												type="success"
												size="mini"
												icon="el-icon-success"
												@click="updateInfo({
										address: newAddress
								}, 'address')"
											/>
											<el-button
												:loading="loading"
												type="danger"
												size="mini"
												icon="el-icon-error"
												@click="closeInput('address')"
											/>
										</el-form-item>
									</el-form>
								</div>
								<div v-else class="info__display">
									<span class="info-text">{{ formattedAddress }}</span>
									<el-button
										type="text"
										size="small"
										icon="el-icon-edit"
										@click="showInput({ name: 'address', defaultValue: '' })"
									/>
								</div>
							</div>
						</div>
					</header>
					<el-switch v-if="!this.is_neopag_credit_config || this.authUser.superuser "
						v-model="saleToken"
						@change="handleChangeSaleToken"
						active-text="Solicitar token na venda"
						inactive-text="Não solicitar token na venda"
					/>
				</div>
			</el-card>
		</el-col>
	</el-row>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import ImageUpload from "vue-image-crop-upload";
import phone from "@/helpers/phone";
import toCNPJ from "@/helpers/toCNPJ";
import cep from "cep-promise";

export default {
	filters: {
		toCNPJ,
		toPhone: phone.format
	},
	components: {
		ImageUpload,
	},
	data() {
		return {
      is_neopag_credit_config: false,
			defaultValue: "",
			showAvatarUploader: false,
			newAvatarDataURL: "",
			loading: false,
			tmpValues: {
				name: "",
				phone: "",
				address: "",
				description: "",
				email: ""
			},
			inputs: {
				name: false,
				phone: false,
				address: false,
				description: false,
				email: false
			},
			autofill: {
				uf: false,
				state: false,
				street: false
			},
			newAddress: {
				cep: "",
				street: "",
				number: "",
				complement: "",
				city: "",
				uf: "",
				latitude: "",
				longitude: ""
			},
			selectedSuggestion: 1,
			shareTitle: "",
			shareDescription: "",
			saleToken: false
		};
	},
	computed: {
		...mapGetters({
      authUser: types.AUTH_USER,
			info: types.STORE_INFO,
			storeID: types.AUTH_ACTIVE_STORE,
      config: types.STORE_CONFIG
		}),
		formattedAddress() {
			const address = this.info.address;
			let str = "";
			if (address.street) str += address.street;
			if (address.number) str += ", " + address.number;
			if (address.cep)
				str += " " + address.cep.replace(/(\d{5})(\d{3})/, "$1-$2");
			if (address.uf) str += " " + address.uf;
			if (address.city) str += " " + address.city;
			return str.trim();
		}
	},
	async mounted() {
		this.loading = true;
		await this.$store.dispatch(types.STORE_INFO, this.storeID);
    this.is_neopag_credit_config = this.config.is_neopag_credit_config;
		this.saleToken = this.info.sale_token;
		this.loading = false;
	},
	methods: {
		formatPhone(phoneNumber) {
			return `+55${phone.unformat(phoneNumber)}`;
		},
		closeInput(name) {
			this.inputs[name] = false;
			this.tmpValues[name] = "";
		},
		showInput({ name, defaultValue }) {
			if (name === "address") {
				this.newAddress = { ...this.info.address };
			} else {
				this.tmpValues[name] = defaultValue;
			}
			this.inputs[name] = true;
		},
		dataURItoBlob(dataURI) {
			var byteString;
			if (dataURI.split(",")[0].indexOf("base64") >= 0)
				byteString = atob(dataURI.split(",")[1]);
			else byteString = unescape(dataURI.split(",")[1]);
			const mimeString = dataURI
				.split(",")[0]
				.split(":")[1]
				.split(";")[0];
			let ia = new Uint8Array(byteString.length);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			return new Blob([ia], { type: mimeString });
		},
		async cropSuccess(imgDataUrl) {
			const fd = new FormData();
			let err = false;
			fd.append("logo", this.dataURItoBlob(imgDataUrl));
			await this.$store
				.dispatch(types.STORE_LOGO_UPDATE, {
					storeID: this.storeID,
					payload: fd
				})
				.catch(error => {
					err = error;
					this.$notify.error({
						message: "Não foi possível atualizar o logo da empresa"
					});
				});
			if (!err) this.newAvatarDataURL = imgDataUrl;
		},
		async handleCEPInput(e) {
			const inputedCep = e.target.value.replace("-", "");
			if (inputedCep.length === 8) {
				const response = await cep(inputedCep);
				response.uf = response.state;
				this.autofill.uf = !!response.state;
				this.autofill.street = !!response.street;
				this.autofill.city = !!response.city;
				this.newAddress = { ...response };
			} else {
				["uf", "street", "city"].forEach(prop => {
					this.autofill[prop] = false;
					this.newAddress[prop] = "";
				});
			}
		},
		async updateInfo(obj, input) {
			this.loading = true;
			const payload = {
				store: obj
			};
			await this.$store
				.dispatch(types.STORE_INFO_UPDATE, {
					storeID: this.storeID,
					payload
				})
				.then(() => {
					this.$notify.success("Informações atualizadas com sucesso!");
				})
				.catch(error => {
					if (this.$raven && error.status === 500) {
						this.$raven.captureException(error.message, { req: error });
					}
					this.$notify.error({ title: error.message });
				})
				.finally(() => {
					this.loading = false;
					this.inputs[input] = false;
					this.autofill.uf = false;
					this.autofill.street = false;
					this.autofill.city = false;
				});
		},
		async handleChangeSaleToken(saleToken) {
			this.loading = true;
			try {
				const payload = {
					store: {
						sale_token: saleToken
					}
				};
				await this.$store.dispatch(types.STORE_INFO_UPDATE, {
					storeID: this.storeID,
					payload
				});
				this.$notify.success({ title: "Configuração alterada com sucesso" });
			} catch (error) {
				if (this.$raven && error.status === 500) {
					this.$raven.captureException(error.message, { req: error });
				}
				this.$notify.error({ title: error.message });
			}
			this.loading = false;
		}
	}
};
</script>
<style lang="scss">
.custom-header {
	font-weight: bold;
	color: $laranja;
}
.info-text {
	color: $preto;
}
.informacoes-empresa {
	.store-card {
		max-width: 320px;
		@media screen and (max-width: 320px) {
			max-width: 280px;
		}
	}
	.info {
		display: flex;
		flex: 1;
		flex-flow: row wrap;
		align-content: flex-start;
		font-family: $title-font;
		font-size: 14px;

		.el-form-item {
			margin-bottom: 0;
		}

		&__label {
			flex: 0 80px;
			width: 80px;
			margin-right: 1rem;
			margin-left: 1rem;
			line-height: 32px;
			color: $preto;
			text-align: right;
			position: relative;

			@include medium-down {
				flex: 0 100%;
				margin: 0.25rem 0 0;
				line-height: 1;
				font-size: 12px;
				text-align: left;
			}

			.due-days {
				position: absolute;
				right: -8px;
				top: -2px;
				color: $laranja;
				font-size: 12px;

				@include medium-down {
					top: 2px;
					right: 0px;
				}
			}
		}

		&__row {
			display: flex;
			flex: 0 100%;
			flex-flow: row wrap;
			min-height: 32px;
		}

		&__display {
			line-height: 32px;
			.el-button {
				margin-left: 0.5rem;
			}
			@include medium-down {
				flex: 0 100%;
				line-height: 1.45;
				font-size: 14px;
				margin-bottom: 4px;
				min-height: 32px;
				.el-button {
					margin-left: 4px;
				}
			}
		}

		&__input {
			display: flex;
			flex-flow: row wrap;

			.el-input {
				flex: 1;
				margin-right: 10px;
			}
		}

		&.--double {
			.info__label {
				flex: 0 250px;
				width: 250px;
				margin-right: 1rem;
				margin-left: 1rem;
				color: $preto;
				text-align: right;
				@include medium-down {
					flex: 0 100%;
					margin: 0.25rem 0 0;
					line-height: 1;
					font-size: 12px;
					text-align: left;
				}
			}
		}
	}

	&__header {
		display: flex;
		flex-flow: row wrap;
		.avatar {
			position: relative;
			flex: 0 80px;
			width: 80px;
			height: 80px;
			@include medium-down {
				flex: 0 100%;
				text-align: center;
				margin-bottom: 1rem;
			}

			&__preview {
				border-radius: 50%;

				@include box-shadow(1);
			}

			&__overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 80px;
				height: 80px;
				pointer-events: none;
				background: rgba(black, 0.5);
				border-radius: 50%;
				opacity: 0;
				transition: all 0.3s ease;
				@include medium-down {
					left: 50%;
					transform: translateX(-50%);
				}
			}

			&__change {
				position: absolute;
				top: 40px;
				left: 40px;
				transform: translate(-50%, -50%);
			}

			&:hover .avatar__overlay {
				pointer-events: all;
				opacity: 1;
			}
		}
	}

	&__main {
		padding-top: 1rem;
		margin-top: 1rem;
		border-top: 1px solid rgba($preto, 0.5);

		> span {
			display: block;
			font-family: $title-font;
			font-size: 14px;
			line-height: 2;
			b {
				display: inline-block;
				width: 176px;
				margin-right: 1rem;
				color: $preto;
				text-align: right;
			}
		}
	}

	.inline-input {
		display: flex;
		flex: 1;
		flex-flow: row wrap;

		.el-input {
			flex: 1;
			margin-right: 10px;
		}
	}
}
</style>
