<template>
  <section>
    <div class="divider"></div>
    <el-row type="flex" justify="center">
      <el-col :md="24" :lg="12">
        <el-card>
          <bank-data @loading="(val) => handleLoad(val)" :load="load" />
        </el-card>
      </el-col>
    </el-row>
    <div class="divider"></div>

    <el-row v-if="!$isMobile" :gutter="10" type="flex" justify="center" align="middle">
      <el-col :md="12" :lg="6">
        <el-card>
          <div class="card-text--wrapper">
            <h4>
              Cobrar taxa extra na fatura do cliente
              <i
                v-popover:billetTax
                :tabindex="-1"
                class="el-icon el-icon-warning"
              />
            </h4>
            <el-popover
              ref="billetTax"
              trigger="hover"
              placement="bottom"
              width="320"
              content="Ativando essa opção o valor da taxa será incluso em cada fatura do cliente"
            />
          </div>
          <div class="card-content--wrapper">
            <el-switch
              v-model="billetsTax"
              active-text="Ativar"
              inactive-text="Desativar"
              size="small"
              @change="changeTaxToggle"
            />
          </div>
          <div v-if="billetsTax && editBilletsTax">
            <div class="money-input--wrapper">
              <money v-model="billetsTaxValue" v-bind="money" class="el-input__inner" />
              <el-button type="success" @click="submitTax">Salvar</el-button>
              <el-button type="danger" @click="editBilletsTax = false">Cancelar</el-button>
            </div>
          </div>
          <div v-if="billetsTax && !editBilletsTax" class="money-rate--wrapper">
            <h4 class="billets-tax-amount">
              Valor da taxa:
              <strong style="font-weight: 600;">{{ $n(billetsTaxValue, 'currency') }}</strong>
            </h4>
            <el-button
              v-if="!editBilletsTax"
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="editBilletsTax = true"
            >Editar</el-button>
          </div>
        </el-card>
      </el-col>
      <el-col :md="12" :lg="6">
        <el-card>
          <div class="card-text--wrapper">
            <h4>Ativar emissão de boletos</h4>
          </div>
          <div class="card-content--wrapper">
            <el-switch
              v-model="billets"
              active-text="Ativar"
              inactive-text="Desativar"
              size="small"
              @change="changeToggle(billets)"
            />
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row v-else :gutter="10">
      <el-col :xs="24" :sm="24">
        <div class="custom--col-mobile">
          <el-card>
            <div class="card-text--wrapper">
              <h4>
                Cobrar taxa extra na fatura do cliente
                <i
                  v-popover:billetTax
                  :tabindex="-1"
                  class="el-icon el-icon-warning"
                />
              </h4>
              <el-popover
                ref="billetTax"
                trigger="hover"
                placement="bottom"
                width="320"
                content="Ativando essa opção o valor da taxa será incluso em cada fatura do cliente"
              />
            </div>
            <div class="card-content--wrapper">
              <el-switch
                v-model="billetsTax"
                active-text="Ativar"
                inactive-text="Desativar"
                size="small"
                @change="changeTaxToggle"
              />
            </div>
            <div v-if="billetsTax && editBilletsTax">
              <div class="money-input--wrapper">
                <money v-model="billetsTaxValue" v-bind="money" class="el-input__inner" />
                <el-button type="success" @click="submitTax">Salvar</el-button>
                <el-button type="danger" @click="editBilletsTax = false">Cancelar</el-button>
              </div>
            </div>
            <div v-if="billetsTax && !editBilletsTax" class="money-rate--wrapper">
              <h4 class="billets-tax-amount">
                Valor da taxa:
                <strong
                  style="font-weight: 600;"
                >{{ $n(billetsTaxValue, 'currency') }}</strong>
              </h4>
              <el-button
                v-if="!editBilletsTax"
                type="text"
                size="small"
                icon="el-icon-edit"
                @click="editBilletsTax = true"
              >Editar</el-button>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24">
        <div class="custom--col-mobile">
          <el-card>
            <div class="card-text--wrapper">
              <h4>Ativar emissão de boletos</h4>
            </div>
            <div class="card-content--wrapper">
              <el-switch
                v-model="billets"
                active-text="Ativar"
                inactive-text="Desativar"
                size="small"
                @change="changeToggle(billets)"
              />
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>

    <div class="divider"></div>
    <el-row type="flex" justify="center">
      <el-col :md="24" :lg="12">
        <el-card>
          <div class="card-text--wrapper">
            <h4>
              Permitir emissão de boletos para todos os seus clientes
              <i
                v-popover:mass
                :tabindex="-1"
                class="el-icon el-icon-warning"
              />
            </h4>
            <el-popover
              ref="mass"
              trigger="hover"
              placement="bottom"
              width="320"
              content="Você perderá todas as suas configurações personalizadas"
            />
          </div>
          <div class="card-content--wrapper">
            <el-button type="success" @click="handleMassBillets">Ativar para todos</el-button>
            <el-button type="danger" @click="handleCancelMassBillets">Desativar para todos</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <div class="divider"></div>
    <el-row type="flex" justify="center">
      <el-col :lg="12">
        <el-card>
          <div class="card-text--wrapper">
            <h2>Status da conta:</h2>
            <p class="account-status">
              <strong style="font-weight: 600;">
                {{ iuguStatus }}
                <i v-popover:status :tabindex="-1" class="el-icon el-icon-warning" />
                <el-popover
                  ref="status"
                  trigger="hover"
                  placement="bottom"
                  width="320"
                  content="Status da sua conta cadastrada"
                />
              </strong>
            </p>
            <h4 v-if="info.iugu_accont_feedback">Motivo: {{ info.iugu_account_feedback }}</h4>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import { Money } from "v-money";
import BankData from "@/components/Settings/Billets/BankData";
export default {
  components: {
    Money,
    BankData,
  },
  props: {
    load: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      billets: true,
      mass_billets: true,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      billetsTax: false,
      editBilletsTax: false,
      billetsTaxValue: 0,
    };
  },
  watch: {
    info: function(newValue) {
      const { billet_tax, billet_tax_amount } = newValue;
      this.billetsTax = billet_tax || false;
      this.billetsTaxValue = parseFloat(billet_tax_amount);
    }
  },
  computed: {
    ...mapGetters({
      info: types.STORE_INFO,
      storeID: types.AUTH_ACTIVE_STORE
    }),
    iuguStatus() {
      const { iugu_account_status } = this.info;
      const statuses = {
        pending: "Em análise",
        accepted: "Aprovado",
        registered: "Registrado",
        rejected: "Recusado",
        not_sent: "Em análise",
        waiting_iugu: "Em análise",
        error: "Erro(Dados incorretos)"
      };
      return statuses[iugu_account_status];
    }
  },
  mounted() {
    const { create_billets } = this.info;
    this.billets = create_billets || false;

    const { billet_tax, billet_tax_amount } = this.info;
    this.billetsTax = billet_tax || false;
    this.billetsTaxValue = parseFloat(billet_tax_amount);
  },
  methods: {
    async handleMassBillets() {
      this.$emit("loading", true);
      this.mass_billets = true;
      try {
        await this.$store.dispatch(types.STORE_TOGGLE_BILLETS, {
          storeID: this.storeID,
          payload: { set: this.mass_billets }
        });
        this.$notify.success("Ativado para todos os clientes!");
      } catch (error) {
        this.$emit("loading", false);
        this.mass_billets = !this.mass_billets;
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.$emit("loading", false);
    },
    async handleCancelMassBillets() {
      this.$emit("loading", true);
      this.mass_billets = false;
      try {
        await this.$store.dispatch(types.STORE_TOGGLE_BILLETS, {
          storeID: this.storeID,
          payload: { set: this.mass_billets }
        });
        this.$notify.success("Desativado para todos os clientes!");
      } catch (error) {
        this.$emit("loading", false);
        this.mass_billets = !this.mass_billets;
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.$emit("loading", false);
    },
    async changeToggle(val) {
      this.$emit("loading", true);
      this.billets = val;
      try {
        await this.$store.dispatch(types.STORE_INFO_UPDATE, {
          storeID: this.storeID,
          payload: { store: { create_billets: this.billets } }
        });
      } catch (error) {
        this.$emit("loading", false);
        this.billets = !this.billets;
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.$emit("loading", false);
    },
    async changeTaxToggle(toggle) {
      try {
        if (toggle) {
          return (this.editBilletsTax = true);
        }
        this.$emit("loading", true);
        await this.$store.dispatch(types.STORE_INFO_UPDATE, {
          storeID: this.storeID,
          payload: {
            store: {
              billet_tax: false
            }
          }
        });
        await this.$store.dispatch(types.STORE_INFO, this.storeID);
        this.editBilletsTax = false;
        this.$emit("loading", false);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
        this.billetsTax = false;
        this.editBilletsTax = false;
        this.$emit("loading", false);
      }
    },
    async submitTax() {
      try {
        this.$emit("loading", true);
        await this.$store.dispatch(types.STORE_INFO_UPDATE, {
          storeID: this.storeID,
          payload: {
            store: {
              billet_tax: true,
              billet_tax_amount: parseFloat(this.billetsTaxValue)
            }
          }
        });
        await this.$store.dispatch(types.STORE_INFO, this.storeID);
        this.editBilletsTax = false;
        this.$emit("loading", false);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
        this.billetsTax = false;
        this.editBilletsTax = false;
        this.$emit("loading", false);
      }
    },
    handleLoad(val) {
      this.$emit("loading", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.account-status {
  font-size: 2rem;
  color: #000;
}
.divider {
  margin: 1rem 0;
}
.card-content--wrapper {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-text--wrapper {
  text-align: center;
}
.custom--col-mobile {
  margin: 10px 0;
}
.money-input--wrapper {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.money-rate--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.billet-step3-container {
  .el-icon {
    color: $laranja;
  }
  padding-top: 20px;
  .status-container {
    h4 {
      margin-top: 4px;
    }
  }
}
</style>
