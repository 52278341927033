<template>
  <section class="billet-step2-container">
    <bank-register-form
      :is-pristine="true"
      @onSubmit="$emit('onNext')"
    />
  </section>
</template>

<script>
import BankRegisterForm from "@/containers/Settings/Billets/BankRegisterForm";
export default {
  components: {
    BankRegisterForm,
  }
}
</script>

<style lang="scss">
  .billet-step2-container {
    padding-top: 20px;
  }
</style>
