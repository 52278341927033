<template>
  <div class="filiais-empresa">
    <el-dialog
      :visible.sync="showFirstBranchDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :width="$isMobile ? '100%' : '60%'"
      class="filiais-empresa__primeira-filial"
    >
      <h2>Você cadastrou sua primeira filial!</h2>
      <p>Para registrarmos as atividades de reparcelamento com a filial, você deve selecionar ela no Login</p>
      <el-button type="primary" @click="handleLogout">Entendi, me leve pro Login!</el-button>
    </el-dialog>
    <el-dialog :width="$isMobile ? '100%' : '60%'" :visible.sync="showStaffLinkDialog">
      <h2>{{ branchPropsName }}</h2>
      <linkar-colaborador
        :branch-props-staffs="branchPropsStaffs"
        :branch-props-id="branchPropsId"
        :branch-props-name="branchPropsName"
      />
    </el-dialog>
    <el-dialog
      @close="resetForm('newBranch')"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :width="$isMobile ? '100%' : '60%'"
      :visible.sync="showCreateBranchDialog"
    >
      <h1 class="create-filial-modal">Adicionar filial</h1>
      <el-form
        ref="newBranch"
        :model="newBranch"
        :rules="newBranchRules"
        size="mini"
        @submit.prevent.native="e => e.preventDefault()"
      >
        <el-form-item prop="name" required>
          <el-input v-model="newBranch.name">
            <template slot="prepend">Nome:</template>
          </el-input>
        </el-form-item>
        <el-form-item v-mask="'XX.XXX.XXX/XXXX-XX'" prop="cnpj" required>
          <el-input v-model="newBranch.cnpj">
            <template slot="prepend">CNPJ:</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="email" required>
          <el-input v-model="newBranch.email" type="email">
            <template slot="prepend">E-mail:</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="phone" required>
          <el-input v-mask="['(##) #####-####', '(##) ####-####']" v-model="newBranch.phone">
            <template slot="prepend">Telefone:</template>
          </el-input>
        </el-form-item>
        <el-form-item v-mask="'XXXXX-XXX'" prop="address.cep" required>
          <el-input v-model="newBranch.address.cep">
            <template slot="prepend">CEP:</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="address.street" required>
          <el-input v-model="newBranch.address.street">
            <template slot="prepend">Logradouro:</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="address.number" required>
          <el-input v-model="newBranch.address.number">
            <template slot="prepend">Número:</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="address.complement">
          <el-input v-model="newBranch.address.complement">
            <template slot="prepend">Complemento:</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="address.neighbourhood" required>
          <el-input v-model="newBranch.address.neighbourhood">
            <template slot="prepend">Bairro:</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="address.city" required>
          <el-input v-model="newBranch.address.city">
            <template slot="prepend">Cidade:</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="address.uf" required>
          <el-input v-model="newBranch.address.uf">
            <template slot="prepend">UF:</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="submitting"
            type="success"
            size="mini"
            @click="createBranch('newBranch')"
          >Salvar</el-button>
          <el-button
            :loading="submitting"
            type="danger"
            size="mini"
            @click="showCreateBranchDialog = false"
          >Cancelar</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="showDeleteDialog"
      :width="$isMobile ? '100%' : '60%'"
      align="center"
      title="Tem certeza que deseja remover essa filial?"
    >
      <el-button :loading="submitting" type="danger" @click="deleteBranch">Sim, excluir</el-button>
      <el-button :loading="submitting" type="secondary" @click="showDeleteDialog = false">Cancelar</el-button>
    </el-dialog>
    <el-dialog />
    <header class="actions">
      <upgrade-suggester :show="!allowBranches">
        <el-button
          size="mini"
          icon="el-icon-plus"
          type="primary"
          @click="allowBranches ? handleCreateClick() : ''"
        >Adicionar</el-button>
      </upgrade-suggester>
    </header>
    <main v-loading="loading" class="list">
      <br />
      <el-table :header-cell-style="{ fontSize: '0.75rem' }" :data="list" size="mini">
        <el-table-column sortable align="center" prop="name" min-width="150px" label="Nome">
          <template slot-scope="scope">
            <template v-if="scope.$index === activeRow">
              <el-input v-model="tmpBranch.name" size="mini" />
            </template>
            <template v-else>{{ scope.row.name }}</template>
          </template>
        </el-table-column>
        <el-table-column prop="cnpj" align="center" min-width="150px" label="CNPJ">
          <template slot-scope="scope">
            <template v-if="scope.$index === activeRow">
              <el-input v-model="tmpBranch.cnpj" size="mini" />
            </template>
            <template v-else-if="scope.row.cnpj">{{ scope.row.cnpj | toCNPJ }}</template>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column
          :formatter="(row, column) => toPhone(row.phone)"
          prop="phone"
          min-width="120px"
          align="center"
          label="Telefone"
        >
          <template slot-scope="scope">
            <template v-if="scope.$index === activeRow">
              <el-input
                v-mask="['XX XXXX-XXXX', 'XX XXXX-XXXXX']"
                v-model="tmpBranch.phone"
                size="mini"
              />
            </template>
            <template v-else>{{ scope.row.phone | toPhone }}</template>
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="80px" label="Colaboradores">
          <template slot-scope="scope">
            <el-tag
              class="staffs-number"
              @click="handleLinkStaffToBranch(scope)"
            >{{ scope.row.staffs ? scope.row.staffs.length : '' }}</el-tag>
            <!-- {{ scope.row.staffs ? handleStaffsList(scope.row.staffs) : '-' }} -->
            <!-- <el-tag
              v-for="staff in scope.row.staffs"
              :key="staff.id"
              :closable="scope.$index === activeRow"
              size="mini"
              @close="removeStaffFromBranch(staff, scope.row.id)">
              {{ staff.user.name }}
            </el-tag>-->
          </template>
        </el-table-column>
        <el-table-column align="center" prop="staffs" width="200px" label="Ações">
          <template slot-scope="scope">
            <div>
              <template v-if="scope.$index === activeRow">
                <el-button
                  size="mini"
                  icon="el-icon-edit"
                  type="success"
                  @click="handleSaveClick"
                >Salvar</el-button>
                <el-button
                  type="secondary"
                  size="mini"
                  icon="el-icon-error"
                  @click="activeRow = -1"
                />
              </template>
              <template v-else>
                <div class="btn-wrapper">
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    type="secondary"
                    @click="handleEditClick(scope.$index)"
                  >Editar filial</el-button>
                  <el-button
                    size="mini"
                    icon="el-icon-edit"
                    type="success"
                    @click="handleLinkStaffToBranch(scope)"
                  >Editar colaboradores</el-button>
                  <el-popover
                    v-if="branchID === scope.row.id || scope.row.is_main_branch"
                    placement="top-end"
                    width="200"
                    trigger="hover"
                    content="Entre em outra filial para poder apagar essa"
                  >
                    <el-button slot="reference" type="secondary" size="mini" icon="el-icon-info" />
                  </el-popover>
                  <el-button
                    v-else
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    @click="handleDeleteClick(scope.row.id)"
                  >Excluir loja</el-button>
                </div>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import phone from "@/helpers/phone";
import toCNPJ from "@/helpers/toCNPJ";
import isCNPJ from "@/helpers/isCNPJ";
import clearFormat from "@/helpers/clearFormat";
import cep from "cep-promise";
import LinkarColaborador from "@/components/Empresa/LinkarColaborador";
import UpgradeSuggester from "@/components/UpgradeSuggester";

export default {
  components: {
    LinkarColaborador,
    UpgradeSuggester
  },
  filters: {
    toCNPJ,
    toPhone: phone.format
  },
  data() {
    const checkCNPJ = (rule, value, callback) => {
      const formatedCNPJ = isCNPJ(value.replace(/[.-/]/g, ""));
      const cleanCNPJ = value.replace(/[./-]/g, "");
      if (!formatedCNPJ) {
        return callback(new Error("Por favor insira um CNPJ válido"));
      } else {
        callback();
      }
    };
    const checkEmail = async (rule, value, callback) => {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return callback();
      } else {
        return callback(new Error("Por favor insira um email válido"));
      }
    };
    const checkNumber = async (rule, value, callback) => {
      const cleanNumber = value.replace(/[()-\s]/g, "");
      if (cleanNumber.length < 10) {
        return callback(new Error("Insira um número de celular válido."));
      } else {
        callback();
      }
    };
    const checkCEP = async (rule, value, callback) => {
      const cleanCEP = value.replace(/[-]/g, "");
      this.loading = true;
      if (cleanCEP.length === 8) {
        try {
          this.cepPromise = await cep(cleanCEP);
          this.handleCepPromiseResponse(this.cepPromise);
          await this.$store.dispatch(types.GET_LAT_LNG, {
            cep: cleanCEP,
            uglyCep: value
          });
          this.loading = false;
          callback();
        } catch (error) {
          await this.$store.dispatch(types.RESET_LAT_LNG, null);
          this.$notify.error(
            "Ocorreu um erro, por favor digite o CEP novamente."
          );
          this.loading = false;
          callback(new Error("Digite o CEP novamente."));
        }
      } else {
        await this.$store.dispatch(types.RESET_LAT_LNG, null);
        this.loading = false;
        callback(new Error("Digite um CEP válido"));
      }
    };
    return {
      activeRow: -1,
      submitting: false,
      loading: false,
      tmpBranch: {
        name: "",
        cnpj: "",
        email: "",
        phone: "",
        address: {
          cep: "",
          street: "",
          number: "",
          complement: "",
          neighbourhood: "",
          city: "",
          uf: ""
        }
      },
      newBranch: {
        name: "",
        cnpj: "",
        email: "",
        phone: "",
        address: {
          cep: "",
          street: "",
          number: "",
          complement: "",
          neighbourhood: "",
          city: "",
          uf: ""
        }
      },
      newBranchRules: {
        name: [{ required: true, message: "Campo obrigatório" }],
        cnpj: [
          {
            required: true,
            trigger: "change",
            validator: checkCNPJ
          }
        ],
        email: [
          {
            required: true,
            trigger: "change",
            validator: checkEmail
          }
        ],
        phone: [
          {
            required: true,
            trigger: "change",
            validator: checkNumber
          }
        ],
        ["address.cep"]: [
          {
            required: true,
            trigger: "change",
            validator: checkCEP
          }
        ],
        ["address.street"]: [{ required: true, message: "Campo obrigatório" }],
        ["address.number"]: [{ required: true, message: "Campo obrigatório" }],
        ["address.neighbourhood"]: [
          { required: true, message: "Campo obrigatório" }
        ],
        ["address.city"]: [{ required: true, message: "Campo obrigatório" }],
        ["address.uf"]: [{ required: true, message: "Campo obrigatório" }]
      },
      branchPropsStaffs: undefined,
      branchPropsId: "",
      branchPropsName: "",
      toVinculateID: undefined,
      showDeleteDialog: false,
      showCreateBranchDialog: false,
      showStaffLinkDialog: false,
      showFirstBranchDialog: false
    };
  },
  computed: {
    ...mapGetters({
      user: types.AUTH_USER,
      list: types.BRANCH_LIST,
      staffs: types.STAFF_LIST,
      storeID: types.AUTH_ACTIVE_STORE,
      branchID: types.AUTH_ACTIVE_BRANCH,
      subscription: types.SUBSCRIPTION_CONFIG,
      location: types.LAT_LNG
    }),
    allowBranches() {
      return (
        this.subscription.multiple_stores && this.subscription.iugu_activated
      );
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.BRANCH_LIST, {
        storeID: this.storeID
      });
      await this.$store.dispatch(types.STAFF_LIST, { storeID: this.storeID });
      await this.$store.dispatch(types.SUBSCRIPTION_DETAILS, this.storeID);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    createBranch(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            this.submitting = true;
            await this.$store.dispatch(types.BRANCH_CREATE, {
              storeID: this.storeID,
              payload: Object.assign({}, this.newBranch, {
                cnpj: clearFormat(this.newBranch.cnpj),
                phone: clearFormat(this.newBranch.phone),
                address: Object.assign({}, this.newBranch.address, {
                  cep: clearFormat(this.newBranch.address.cep),
                  latitude: this.location?.lat,
                  longitude: this.location?.lng
                })
              })
            });
            this.$notify.success("Filial criada com sucesso!");
            this.showCreateBranchDialog = false;
            this.submitting = false;
            if (this.list.length === 1) {
              const currentStaff = this.staffs.find(it => {
                return it.user.id === this.user.id;
              });

              await this.$store.dispatch(types.BRANCH_LINK_STAFF, {
                storeID: this.storeID,
                branchID: this.list[0].id,
                staffID: currentStaff.id
              });

              this.showFirstBranchDialog = true;
            }
          } catch (error) {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          }
        } else {
          return false;
        }
        this.submitting = false;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async deleteBranch() {
      this.submitting = true;
      await this.$store
        .dispatch(types.BRANCH_DELETE, {
          storeID: this.storeID,
          branchID: this.toDeleteID
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.$notify.success("Filial removida com sucesso!");
      this.showDeleteDialog = false;
      this.submitting = false;
    },
    async removeStaffFromBranch(staff, toDeleteID) {
      try {
        await this.$store.dispatch(types.BRANCH_UNLINK_STAFF, {
          storeID: this.storeID,
          branchID: toDeleteID,
          staffID: staff.id
        });
        this.$notify.success("Colaborador removido com sucesso");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    handleCreateClick() {
      this.newBranch = {
        name: "",
        cnpj: "",
        email: "",
        phone: "",
        address: {}
      };
      if (this.$refs.newBranch) {
        this.$refs.newBranch.clearValidate();
      }
      this.$nextTick(() => {
        this.showCreateBranchDialog = true;
      });
    },
    handleDeleteClick(id) {
      this.toDeleteID = id;
      this.showDeleteDialog = true;
    },
    handleEditClick(row) {
      this.activeRow = row;
      this.tmpBranch = { ...this.list[row] };
    },
    handleLinkStaffToBranch(scope) {
      this.branchPropsStaffs = scope.row.staffs;
      this.branchPropsId = scope.row.id;
      this.branchPropsName = scope.row.name;
      this.showStaffLinkDialog = true;
    },
    handleStaffsList(staffArray) {
      const staffName = staffArray.forEach(staff => staff.user.name);
      return staffName;
    },
    async handleSaveClick() {
      if (
        this.tmpBranch.name === "" ||
        this.tmpBranch.cnpj === "" ||
        this.tmpBranch.phone === ""
      )
        return;
      this.submitting = true;
      await this.$store
        .dispatch(types.BRANCH_UPDATE, {
          storeID: this.storeID,
          branchID: this.list[this.activeRow].id,
          payload: {
            branch: this.tmpBranch
          }
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.$notify.success("Dados da filial atualizados com sucesso!");
      this.submitting = false;
      this.activeRow = -1;
    },
    async handleLogout() {
      await this.$store.dispatch(types.AUTH_LOGOUT).catch(error => {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      });
      this.$router.push("/login");
    },
    handleCepPromiseResponse(value) {
      this.newBranch.address.street = value.street;
      this.newBranch.address.city = value.city;
      this.newBranch.address.uf = value.state;
      this.newBranch.address.neighbourhood = value.neighborhood;
    }
  }
};
</script>

<style lang="scss">
.filiais-empresa {
  &__primeira-filial {
    text-align: center;
    h2 {
      font-weight: bold;
      margin-bottom: 1rem;
    }
    p {
      font-family: $title-font;
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 1rem;
    }
    button {
      text-transform: uppercase;
    }
  }
  .el-table .disabled {
    pointer-events: none;
    display: none;
  }
  .btn-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    .el-button + .el-button {
      margin-top: 0.5rem;
      margin-left: 0 !important;
    }
  }
}
.staffs-number {
  cursor: pointer !important;
}
.create-filial-modal {
  color: $laranja;
  font-weight: bold;
}
</style>
