<template>
  <section v-loading="loading">
    <div class="permissions-wrapper">
      <div class="content">
        <h4>Painel</h4>
        <el-switch v-model="permissions.dashboard" />
      </div>
      <div class="content">
        <h4>Cadastrar cliente</h4>
        <el-switch v-model="permissions.add_clients" />
      </div>
      <div class="content">
        <h4>Alterar limite sugerido pela Neopag</h4>
        <el-switch v-model="permissions.change_total_limit" />
      </div>
      <div class="content">
        <h4>Venda</h4>
        <el-switch v-model="permissions.sales" />
      </div>
      <div class="content">
        <h4>Vender se o documento de identidade nao estiver enviado</h4>
        <el-switch v-model="permissions.sale_if_documents_not_sent" />
      </div>
      <div class="content">
        <h4>Vender se o documento de identidade nao estiver aprovado</h4>
        <el-switch v-model="permissions.sale_if_documents_not_aproved" />
      </div>
      <div class="content">
        <h4>Vender se o comprovante de endereço não estiver enviado</h4>
        <el-switch v-model="permissions.sale_if_address_not_sent" />
      </div>
      <div class="content">
        <h4>Vender se o comprovante de endereço não estiver aprovado</h4>
        <el-switch v-model="permissions.sale_if_address_not_aproved" />
      </div>
      <div class="content">
        <h4>Vender se o comprovante de renda não estiver enviado</h4>
        <el-switch v-model="permissions.sale_if_income_not_sent" />
      </div>
      <div class="content">
        <h4>Vender se o comprovante de renda não estiver aprovado</h4>
        <el-switch v-model="permissions.sale_if_income_not_aproved" />
      </div>
      <div class="content">
        <h4>Vender se o telefone não estiver confirmado</h4>
        <el-switch v-model="permissions.sale_if_phone_not_confirmed" />
      </div>
      <div class="content">
        <h4>Cancelar venda</h4>
        <el-switch v-model="permissions.cancel_sale" />
      </div>
      <div class="content">
        <h4>Alterar vencimento da primeira parcela</h4>
        <el-switch v-model="permissions.change_first_installment_date" />
      </div>
      <div class="content">
        <h4>Recebimento</h4>
        <el-switch v-model="permissions.payments" />
      </div>
      <div class="content">
        <h4>Cancelar Recebimento</h4>
        <el-switch v-model="permissions.cancel_payment" />
      </div>
      <div class="content">
        <h4>Desconto na fatura</h4>
        <el-switch v-model="permissions.statement_discount" />
      </div>
      <div class="content">
        <h4>Desconto no adiantamento de itens da fatura</h4>
        <el-switch v-model="permissions.installment_discount" />
      </div>
      <div class="content">
        <h4>Painel de cobranças</h4>
        <el-switch v-model="permissions.charging" />
      </div>
      <div class="content">
        <h4>Reparcelamentos</h4>
        <el-switch v-model="permissions.deals" />
      </div>
      <div class="content">
        <h4>Relatórios</h4>
        <el-switch v-model="permissions.reports" />
      </div>
      <div class="content">
        <h4>Configurações</h4>
        <el-switch v-model="permissions.settings" />
      </div>
    </div>
    <div class="permissions-footer">
      <el-button type="primary" round @click="updateStaffPermissions">Salvar</el-button>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  props: {
    staffId: {
      type: [String, null],
      default: null
    }
  },
  data() {
    return {
      loading: false,
      permissions: {
        add_clients: false,
        change_total_limit: false,
        cancel_sale: false,
        charging: false,
        dashboard: false,
        deals: false,
        payments: false,
        cancel_payment: false,
        statement_discount: false,
        installment_discount: false,
        reports: false,
        sales: false,
        change_first_installment_date: false,
        sale_if_documents_not_aproved: false,
        sale_if_documents_not_sent: false,
        sale_if_address_not_sent: false,
        sale_if_address_not_aproved: false,
        sale_if_income_not_sent: false,
        sale_if_income_not_aproved: false,
        sale_if_phone_not_confirmed: false,
        settings: false
      }
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE
      // permissions: types.STORE_STAFF_PERMISSIONS,
    })
  },
  watch: {
    staffId: async function(value) {
      this.loading = true;
      const resp = await this.$store.dispatch(types.STORE_STAFF_PERMISSIONS, {
        storeId: this.storeID,
        staffId: value
      });
      this.permissions = resp;
      this.loading = false;
    }
  },
  async mounted() {
    this.loading = true;
    try {
      const resp = await this.$store.dispatch(types.STORE_STAFF_PERMISSIONS, {
        storeId: this.storeID,
        staffId: this.staffId
      });
      this.permissions = resp;
      this.loading = false;
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    async updateStaffPermissions() {
      this.loading = true;
      try {
        const resp = await this.$store.dispatch(
          types.STORE_UPDATE_STAFF_PERMISSIONS,
          {
            storeId: this.storeID,
            staffId: this.staffId,
            permissions: this.permissions
          }
        );
        this.permissions = resp;
        this.$notify.success({ title: "Permissões atualizadas!" });
        this.$emit("closePermissionsModal");

        const storeData = await this.$store.dispatch(
          types.STORE_INFO,
          this.storeID
        );

        await this.$store.dispatch(types.AUTH_ACTIVE_STORE_DETAILS, {
          ...storeData,
          permissions: resp
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
.permissions-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 22px;
  .content {
    text-align: center;
    h4 {
      margin-bottom: 8px;
    }
  }
}
.permissions-footer {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-right: 48px;
}
</style>
