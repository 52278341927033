<template>
  <div class="configuracoes">
    <el-tabs
      v-if="authOrigin !== 'app'"
      :value="tab"
      @tab-click="handleClick">
      <el-tab-pane
        name="config"
        label="Minha Loja"
        route-name="Configurações"/>
      <el-tab-pane
        v-if="!isIOS"
        name="subscription"
        label="Assinatura e pagamento"
        route-name="Configurações - Assinatura e pagamento"/>
      <el-tab-pane v-if="!this.is_neopag_credit_config || this.authUser.superuser"
        name="billets"
        label="Boletos"
        route-name="Configurações - Boletos"/>
      <el-tab-pane
        name="plans"
        label="Recebimentos, vendas e acordos"
        route-name="Configurações - Planos"/>
      <el-tab-pane
        name="staff"
        label="Colaboradores"
        route-name="Configurações - Colaboradores"/>
      <el-tab-pane
        label="Filiais"
        route-name="Configurações - Filiais"
        name="branches"/>
      <el-tab-pane
        name="marketing"
        label="Marketing"
        route-name="Marketing"/>
      <el-tab-pane
        name="reset-password"
        label="Resetar senha"
        route-name="Resetar Senha"/>
    </el-tabs>
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      tab: this.$route.meta.params.tab,
      is_neopag_credit_config: false
    };
  },
  computed: {
    ...mapGetters({
      authUser: types.AUTH_USER,
      authOrigin: types.AUTH_ORIGIN,
      config: types.STORE_CONFIG,
      storeID: types.AUTH_ACTIVE_STORE,
    }),
    isIOS() {
      if (navigator && navigator.appVersion.indexOf('iPhone') !== -1 ||
          navigator && navigator.appVersion.indexOf('iPad') !== -1
      ) {
        return true
      }
      return false
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
      this.is_neopag_credit_config = this.config.is_neopag_credit_config;
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    handleClick(e) {
      const name = e.$attrs["route-name"];
      this.$router.push({ name });
    }
  }
};
</script>
<style lang="scss">
.configuracoes {
  padding: 16px;
  background: #FFFFFF;
  width: 100%;
  height: 100%;
}
</style>
