<template>
  <section>
    <el-row type="flex" justify="center">
      <el-form
        ref="bankDataForm"
        :model="bankDataForm"
        :rules="bankDataFormRules"
        class="bank-form"
        @submit.native.prevent
      >
        <div class="custom--divider" />
        <el-row type="flex" justify="space-around">
          <div class="custom--name">
            <el-form-item
              v-if="isIuguConfigured"
              size="mini"
              label="Nome do Responsável"
              prop="owner_name"
            >
              <el-input v-model="bankDataForm.owner_name" />
            </el-form-item>
          </div>
          <div class="custom--document">
            <el-form-item
              v-if="isIuguConfigured"
              size="mini"
              label="CPF do Responsável"
              prop="owner_cpf"
            >
              <el-input v-mask="'###.###.###-##'" v-model="bankDataForm.owner_cpf" />
            </el-form-item>
          </div>
        </el-row>
      <el-form-item label="Tipo de Titular" size="mini" prop="holder_type">
        <el-select v-model="bankDataForm.holder_type">
          <el-option label="Pessoa Jurídica" value="pj" />
          <el-option label="Pessoal Física" value="pf" />
        </el-select>
      </el-form-item>
      </el-form>
    </el-row>
    <el-row type="flex" justify="center">
      <el-button
        type="primary"
        size="medium"
        @click="handleSubmit('bankDataForm')"
      >Solicitar alteração</el-button>
    </el-row>
  </section>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import isCPF from "@/helpers/isCPF";
export default {
  data() {
    const checkCPF = (rule, value, callback) => {
      const formatedCPF = isCPF(value.replace(/[.-]/g, ""));
      if (!formatedCPF) {
        return callback(new Error("Por favor insira um CPF válido"));
      } else {
        return callback();
      }
    };
    return {
      bankDataForm: {
        owner_name: "",
        owner_cpf: "",
        holder_type: "pj"
      },
      bankDataFormRules: {
        owner_name: [
          { required: true, message: "Campo obrigatório", trigger: "blur" }
        ],
        owner_cpf: [{ required: true, validator: checkCPF, trigger: "change" }]
      }
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE,
      banksList: types.STORE_BANKS,
      store: types.STORE_INFO,
      account: types.STORE_BANK_ACCOUNT
    }),
    isIuguConfigured() {
      if (this.store.billet_config === "iugu") {
        return true;
      }
      return false;
    }
  },
  async mounted() {
    this.$emit("loading", true);
    try {
      await this.$store.dispatch(types.STORE_BANKS);
      await this.$store.dispatch(
        types.STORE_BANK_ACCOUNT_DETAILS,
        this.storeID
      );
      await this.$store.dispatch(types.STORE_WITHDRAWAL_LIST, this.storeID);
      await this.$store.dispatch(
        types.STORE_GET_BILLETS_BALANCES,
        this.storeID
      );
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.$emit("loading", false);
  },
  methods: {
    async handleSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const payload = {
            account_type: this.bankDataForm.account_type,
            owner_name: this.bankDataForm.owner_name,
            owner_cpf: this.bankDataForm.owner_cpf.replace(/\D/g, "")
          };
          try {
          } catch (error) {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          }
        } else {
          this.$notify.warning({ title: "Verifique os dados iniformados." });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom--name {
  @media screen and (min-width: 769px) {
    width: 40%;
  }
}
.custom--document {
  @media screen and (min-width: 769px) {
    width: 40%;
  }
}
.custom--divider {
  margin: 0.5rem 0;
}
</style>
