export default rawCNPJ => {
  const seq1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const seq2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  const isInvalid = (s, r, p) => r !== parseInt(s.substring(p, p + 1), 12);
  const sum = (seq) => seq.reduce((sum, digit, index) => {
    sum += digits[index] * digit;
    return sum;
  }, 0)

  let cnpj = rawCNPJ.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;
  if (cnpj.length !== 14) return false;
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  ) return false;

  const digits = cnpj.split('');
  const sum1 = sum(seq1);
  const sum2 = sum(seq2);

  const dig1 = sum1 % 11 < 2 ? 0 : 11 - (sum1 % 11);
  const dig2 = sum2 % 11 < 2 ? 0 : 11 - (sum2 % 11);

  if (isInvalid(cnpj, dig1, 12)) return false;
  if (isInvalid(cnpj, dig2, 13)) return false;

  return true
};
