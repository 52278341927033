<template>
  <section>
    <el-row type="flex" justify="center">
      <el-form
        ref="bankDataForm"
        :model="bankDataForm"
        :rules="bankDataFormRules"
        class="bank-form"
        @submit.prevent.native="e => e.preventDefa()"
      >
        <el-form-item label="Banco" size="mini" prop="bank_id">
          <el-select v-model="bankDataForm.bank_id">
            <el-option
              v-for="bank in banksList"
              :key="bank.id"
              :label="`${bank.code} - ${bank.name}`"
              :value="bank.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Tipo de Conta" size="mini" prop="account_type">
          <el-select v-model="bankDataForm.account_type">
            <el-option label="Corrente" value="checking" />
            <el-option label="Poupança" value="savings" />
          </el-select>
          <div class="custom--divider" />
          <div v-if="activeBankRules" class="bank-form__fields">
            <el-row type="flex">
              <div class="custom--agency">
                <el-form-item
                  :rules="[{
                required: true, message: 'Campo obrigatório', trigger: 'blur'
              }]"
                  size="mini"
                  prop="branch"
                  required
                >
                  <el-input
                    v-mask="'#'.repeat(activeBankRules.branch_length)"
                    v-model="bankDataForm.branch"
                  >
                    <div slot="prepend">Agência (Sem dígito)</div>
                  </el-input>
                </el-form-item>
              </div>
              <div class="custom--agency-digit">
                <el-form-item
                  v-if="activeBankRules.branch_digit_alphanumeric"
                  :rules="[{
                message: 'Campo obrigatório', trigger: 'blur'
              }]"
                  size="mini"
                  prop="branch_digit"
                  required
                >
                  <el-input v-model="bankDataForm.branch_digit" style="margin-left: 10px;">
                    <div slot="prepend">Dígito</div>
                  </el-input>
                </el-form-item>
                <el-form-item
                  v-else-if="!activeBankRules.branch_digit_alphanumeric"
                  :rules="[{
                pattern: /\d+/, message: 'Inválido', trigger: 'blur'
              }]"
                  size="mini"
                  prop="branch_digit"
                >
                  <el-input v-model="bankDataForm.branch_digit" style="margin-left: 10px;">
                    <div slot="prepend">Dígito</div>
                  </el-input>
                </el-form-item>
              </div>
            </el-row>
            <el-row type="flex">
              <div class="custom--agency">
                <el-form-item
                  :rules="[{
                required: true, message: 'Campo obrigatório', trigger: 'blur'
              }, {
                min: activeBankRules.min_account_length, max: activeBankRules.max_account_length,
                message: 'Tamanho inválido', trigger: 'blur'
              }, {
                pattern: /\d+/, message: 'Somente números', trigger: 'blur'
              }]"
                  size="mini"
                  prop="number"
                  required
                >
                  <el-input v-model="bankDataForm.number">
                    <div slot="prepend">Conta (Sem dígito)</div>
                  </el-input>
                </el-form-item>
              </div>
              <div class="custom--agency-digit">
                <el-form-item
                  v-if="activeBankRules.digit_length && activeBankRules.digit_alphanumeric"
                  :rules="[{
                min: 1, max: activeBankRules.digit_length,
                message: 'Tamanho inválido', trigger: 'blur'
              }]"
                  size="mini"
                  prop="digit"
                >
                  <el-input v-model="bankDataForm.digit" style="margin-left: 10px;">
                    <div slot="prepend">Dígito</div>
                  </el-input>
                </el-form-item>
                <el-form-item
                  v-else-if="activeBankRules.digit_length && !activeBankRules.digit_alphanumeric"
                  size="mini"
                  prop="digit"
                >
                  <el-input
                    v-mask="'#'.repeat(activeBankRules.digit_length)"
                    v-model="bankDataForm.digit"
                    style="margin-left: 10px;"
                  >
                    <div slot="prepend">Dígito</div>
                  </el-input>
                </el-form-item>
              </div>
            </el-row>
          </div>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row type="flex" justify="center">
      <el-button
        type="primary"
        size="medium"
        @click="handleVerificationSubmit('bankDataForm')"
      >Confirmar alteração</el-button>
    </el-row>
    <el-dialog
      class="account-dialog"
      :visible.sync="accountSubmitModal"
      :fullscreen="$isMobile"
      append-to-body
    >
      <h1 class="account-dialog--title">Atenção</h1>
      <p>Confirme os dados abaixo, pois uma vez enviados não será possível alterá-los</p>
      <div class="account-dialog--info-wrapper">
        <el-card>
          <div slot="header" class="card--title">Dados da conta bancária</div>
          <p>
            Banco:
            <b>{{bankName}}</b>
          </p>
          <p>
            Tipo de Conta:
            <b>{{accountType}}</b>
          </p>
          <p>
            Agência:
            <b>{{bankDataForm.branch}}</b>
          </p>
          <p>
            Dígito da Agência:
            <b>{{bankDataForm.branch_digit}}</b>
          </p>
          <p>
            Conta:
            <b>{{bankDataForm.number}}</b>
          </p>
          <p>
            Dígito da Conta:
            <b>{{bankDataForm.digit}}</b>
          </p>
        </el-card>
        <el-card>
          <div slot="header" class="card--title">Titular da conta bancária</div>
          <p>
            Nome do Responsável:
            <b>{{account.owner_name}}</b>
          </p>
          <p>
            CPF do Responsável:
            <b>{{account.owner_cpf}}</b>
          </p>
          <p>
            Titular da Conta:
            <b>{{holderType}}</b>
          </p>
        </el-card>
      </div>
      <div class="buttons--card__wrapper">
        <el-button
          type="success"
          @click="handleSubmit('bankDataForm')"
        >Sim, confirmar dados e enviar</el-button>
        <el-button type="danger" @click="accountSubmitModal = false">Não, alterar dados</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      accountSubmitModal: false,
      bankDataForm: {
        // is_default: true,
        account_type: "checking",
        bank_id: "",
        branch: "", //agencia
        branch_digit: "", //digito ag
        number: "", //conta
        digit: "", //digito conta
        owner_name: "",
        owner_cpf: "",
        price_range_min: 0,
        price_range_max: 0,
        sell_physical_prodcuts: true
      },
      bankDataFormRules: {
        bank_id: [
          { required: true, message: "Campo obrigatório", trigger: "blur" }
        ],
        owner_name: [
          { required: true, message: "Campo obrigatório", trigger: "blur" }
        ],
        owner_cpf: [
          { required: true, message: "Campo obrigatório", trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE,
      banksList: types.STORE_BANKS,
      store: types.STORE_INFO,
      account: types.STORE_BANK_ACCOUNT
    }),
    activeBankRules() {
      if (this.banksList.length) {
        return this.banksList.find(it => it.id === this.bankDataForm.bank_id);
      }
      return false;
    },
    isIuguConfigured() {
      if (this.store.billet_config === "iugu") {
        return true;
      }
      return false;
    },
    holderType() {
      switch (this.account.holder_type) {
        case "pj":
          return "Pessoa Jurídica";
        case "pf":
          return "Pessoa Física";
        default:
          return "";
      }
    },
    accountType() {
      switch (this.bankDataForm.account_type) {
        case "checking":
          return "Conta Corrente";
        case "savings":
          return "Conta Poupança";
        default:
          return "";
      }
    },
    bankName() {
      if (this.bankDataForm.bank_id) {
        const bank = this.banksList.find(
          it => it.id === this.bankDataForm.bank_id
        );
        return bank.name;
      } else {
        return "";
      }
    }
  },
  async mounted() {
    this.$emit("loading", true);
    try {
      await this.$store.dispatch(types.STORE_BANKS);
      await this.$store.dispatch(
        types.STORE_BANK_ACCOUNT_DETAILS,
        this.storeID
      );
      await this.$store.dispatch(types.STORE_WITHDRAWAL_LIST, this.storeID);
      await this.$store.dispatch(
        types.STORE_GET_BILLETS_BALANCES,
        this.storeID
      );
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.$emit("loading", false);
  },
  methods: {
    handleVerificationSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if(valid) {
          this.accountSubmitModal = true
        } else {
          this.$notify.warning({title: 'Verifique os dados informados.'})
        }
      })
    },
    handleSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            await this.$store.dispatch(types.STORE_BANK_ACCOUNT, {
              store_id: this.storeID,
              account_type: this.bankDataForm.account_type,
              bank_id: this.bankDataForm.bank_id,
              branch: this.bankDataForm.branch,
              branch_digit: this.bankDataForm.branch_digit,
              number: this.bankDataForm.number,
              digit: this.bankDataForm.digit,
              owner_cpf: this.account.owner_cpf.replace(/[.-]/g, ""),
              owner_name: this.account.owner_name
            });
            this.$notify.success({
              title: "Sucesso",
              message:
                "Os dados foram enviados com sucesso, aguarde até 2 dias úteis."
            });
          } catch (error) {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          }
        } else {
          this.$notify.warning({ title: "Verifique os dados iniformados." });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom--agency {
  @media screen and (min-width: 769px) {
    width: 40%;
  }
}
.custom--agency-digit {
  @media screen and (min-width: 769px) {
    width: 20%;
  }
}
.custom--divider {
  margin: 0.5rem 0;
}
.account-dialog {
  &--title {
    color: $laranja;
    font-weight: bold;
  }

  &--info-wrapper {
    display: grid;
    @media screen and(max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    margin: 1rem 0;
    p {
      margin: 13px 0;
    }
  }
  .card--title {
    color: $laranja;
    font-weight: bold;
  }
  .buttons--card__wrapper {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    @media screen and(max-width: 767px) {
      flex-direction: column;
      button {
        &:nth-child(2) {
          margin-top: 1rem;
        }
      }
    }
  }
}
</style>
