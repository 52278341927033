<template>
  <el-row :gutter="10" class="plan-edit">
    <el-col v-for="(plan, index) in plansArray" :key="index" :lg="8" :md="8" :sm="12" :xs="24">
      <el-card class="plan-edit__cards">
        <div slot="header" class="clearfix">
          <span v-if="!plansArray[index].is_neopag_credit_plan">Edição de plano</span>
          <span v-else>Plano <i style="color:#ff5e44;">[neopag.crédito]</i></span>
          <div style="float:right;">
            <el-button
              :disabled="plansArray[index].is_neopag_credit_plan"
              :loading="submitting"
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="deletePlan(plan, index)"
            />
            <el-button
              :disabled="plansArray[index].is_neopag_credit_plan"
              :loading="submitting"
              size="mini"
              icon="el-icon-check"
              type="success"
              @click="handleSave"
            />
          </div>
        </div>
        <el-form>
          <el-form-item label="Nome do plano">
            <el-input v-model="plansArray[index].name" size="mini" :disabled="plansArray[index].is_neopag_credit_plan" />
          </el-form-item>
          <el-form-item class="plan-edit__cards--installments" label="Quantidade de parcelas">
            <!-- eslint-disable-next-line vue/no-parsing-error -->
            <br />
            <span>De</span>
            <el-input v-model.number="plansArray[index].installment.from" size="mini" :disabled="plansArray[index].is_neopag_credit_plan"/>
            <span>até</span>
            <el-input v-model.number="plansArray[index].installment.to" size="mini" :disabled="plansArray[index].is_neopag_credit_plan"/>
          </el-form-item>
          <el-form-item label="Percentual de juros mensal">
            <el-input v-model.number="plansArray[index].interest" type="number" size="mini" :disabled="plansArray[index].is_neopag_credit_plan"/>
          </el-form-item>
          <el-form-item label="Percentual mínimo de entrada">
            <el-input v-model.number="plansArray[index].down_payment" type="number" size="mini" :disabled="plansArray[index].is_neopag_credit_plan"/>
          </el-form-item>
          <div class="row-input delete">
            <div class="input">
              <div class="label">&nbsp;</div>
            </div>
          </div>
        </el-form>
      </el-card>
    </el-col>
    <el-col :lg="8" :md="8" :sm="12" :xs="24">
      <el-card shadow="hover" align="middle" class="plan_edit__add-plan">
        <el-button
          :loading="submitting"
          size="mini"
          icon="el-icon-plus"
          type="secondary"
          @click="plansArray.push(newPlan())"
        >PLANO</el-button>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: "sale"
    }
  },
  data() {
    return {
      submitting: false,
      plansArray: [],
      editingSalesProps: this.editingSales
    };
  },
  computed: {
    ...mapGetters({
      salePlans: types.STORE_PLANS,
      storeID: types.AUTH_ACTIVE_STORE,
      dealPlans: types.STORE_DEAL_PLANS
    }),
    actionPrefix() {
      if (this.type) {
        const start = "STORE_";
        const type = this.type === "sale" ? "" : "DEAL_";
        const end = "PLANS";
        return [start, type, end].join("");
      } else {
        return "";
      }
    },
    activePlans() {
      return this[this.type + "Plans"];
    }
  },
  mounted() {
    this.plansArray = JSON.parse(JSON.stringify(this.activePlans));
  },
  methods: {
    newPlan() {
      return new Object({
        name: "Novo plano",
        interest: 0,
        down_payment: 0,
        grace_period: 0,
        installment: {
          from: 1,
          to: 1
        }
      });
    },
    handleSave() {
      this.submitting = true;
      const promises = [];

      this.plansArray.forEach(plan => {
        const planID = plan.id;
        const payload = {
          plan
        };
        if (planID) {
          promises.push(
            this.$store.dispatch(types[this.actionPrefix + "_UPDATE"], {
              storeID: this.storeID,
              planID,
              payload
            })
          );
        } else {
          promises.push(
            this.$store.dispatch(types[this.actionPrefix + "_CREATE"], {
              storeID: this.storeID,
              payload
            })
          );
        }
      });

      Promise.all(promises)
        .then(() => {
          this.$notify.success({
            message: "Planos salvos!"
          });
          this.$emit("handleCloseEdit");
          this.submitting = false;
        })
        .catch(() => {
          this.$notify.error({
            message: "Ocorreu um problema!"
          });
          this.submitting = false;
        });
    },
    deletePlan(plan, index) {
      if (plan.id) {
        this.$confirm(`Apagar "${plan.name}"?`).then(() => {
          this.removePlan(plan.id);
          if (this.plansArray.length === 1) {
            this.$emit("handleCloseEdit");
          }
        });
      } else {
        this.plansArray.splice(index, 1);
      }
    },
    async removePlan(planID) {
      this.submitting = true;
      await this.$store.dispatch(types[this.actionPrefix + "_DELETE"], {
        storeID: this.storeID,
        planID
      });
      this.plansArray = [...this.activePlans];
      this.submitting = false;
    }
  }
};
</script>

<style lang="scss">
.plan-edit {
  span {
    color: $preto;
  }
  margin-top: 1rem;
  > .el-col {
    margin-bottom: 1rem;
  }
  h5 {
    margin-bottom: 0.5rem;
  }
  &__edit-buttons {
    margin-bottom: 1rem;
  }
  &__cards {
    &--installments {
      .el-form-item__content {
        line-height: 20px;
        .el-input {
          width: 14%;
          @media (max-width: 764px) {
            width: 20%;
          }
        }
      }
    }
  }
  &__add-plan {
  }
  .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
  .el-form-item {
    .el-form-item__label {
      font-size: 12px !important;
      line-height: 0;
    }
    .el-form-item__content {
      font-size: 12px;
      .el-input--mini {
        font-size: 12px !important;
        .el-input__inner {
          font-size: 12px !important;
          line-height: 0;
        }
      }
    }
  }
}
</style>
