<template>
  <div class="colaboradores-loja">
    <el-dialog :width="$isMobile ? '100%' : '60%'" :visible.sync="showCreateDialog">
      <criar-colaborador />
    </el-dialog>
    <el-dialog
      :width="$isMobile ? '100%' : '60%'"
      :visible.sync="showPermissionDialog"
      v-if="showPermissionDialog"
      @destroy-on-close="true"
      title="Permissões do colaborador"
    >
      <staff-permissions
        @closePermissionsModal="showPermissionDialog = false"
        :staff-id="selectedStaff"
      />
    </el-dialog>
    <el-dialog
      :visible.sync="showDeleteDialog"
      align="center"
      title="Tem certeza que deseja remover essa colaborador?"
    >
      <el-button :loading="submitting" type="danger" @click="deleteStaff">Sim, excluir</el-button>
      <el-button :loading="submitting" type="secondary" @click="showDeleteDialog = false">Cancelar</el-button>
    </el-dialog>
    <header class="actions">
      <el-button size="mini" icon="el-icon-plus" type="primary" @click="handleCreateClick">Adicionar</el-button>
    </header>
    <main v-loading="loading" class="list">
      <br />
      <el-table :header-cell-style="{ fontSize: '0.75rem' }" :data="list" size="mini">
        <el-table-column sortable prop="name" label="Nome">
          <template slot-scope="scope">
            <template v-if="scope.$index === activeRow">
              <el-popover
                :disabled="!tmpStaff.inApp"
                placement="top-end"
                title="Registrado no App"
                width="200"
                trigger="hover"
                content="Este usuário deve trocar suas informações pessoais através do app"
              >
                <el-input
                  slot="reference"
                  :disabled="tmpStaff.inApp"
                  v-model="tmpStaff.name"
                  size="mini"
                />
              </el-popover>
            </template>
            <template v-else>{{ scope.row.user.name }}</template>
          </template>
        </el-table-column>
        <el-table-column prop="role" label="Cargo">
          <template slot-scope="scope">
            <template v-if="scope.$index === activeRow">
              <el-select v-model="tmpStaff.role" size="mini" style="width: 150px">
                <el-option value="owner" label="Dono" />
                <el-option value="operator" label="Operador" />
              </el-select>
            </template>
            <template v-else>{{ scope.row.role | toRole }}</template>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="branches"
          label="N° de filiais">
          <template slot-scope="scope">
            <template v-if="scope.$index === activeRow">
              <el-select
                v-model="tmpBranches"
                multiple
                size="mini">
                <el-option
                  v-for="(branch, index) in branches"
                  :key="branch.id + index"
                  :value="branch.id"
                  :label="branch.name" />
              </el-select>
            </template>
            <template v-else>{{ scope.row.branches ? scope.row.branches.length : 0 }}</template>
          </template>
        </el-table-column>-->
        <el-table-column align="right" prop="staffs" width="420" label="Ações">
          <template slot-scope="scope">
            <el-button
              :disabled="list.length <= 1"
              size="mini"
              icon="el-icon-edit"
              type="secondary"
              @click="handleEditPermissions(scope.row.id)"
            >Editar Permissões</el-button>
            <template v-if="scope.$index === activeRow">
              <el-button
                size="mini"
                icon="el-icon-edit"
                type="success"
                @click="handleSaveClick(scope.row.id)"
              >Salvar</el-button>
              <el-button type="secondary" size="mini" icon="el-icon-error" @click="activeRow = -1" />
            </template>
            <template v-else>
              <el-button
                :disabled="list.length <= 1"
                size="mini"
                icon="el-icon-edit"
                type="secondary"
                @click="handleEditClick(scope.$index)"
              >Editar colaborador</el-button>
              <el-button
                :disabled="list.length <= 1"
                type="secondary"
                size="mini"
                icon="el-icon-delete"
                @click="handleDeleteClick(scope.row.id)"
              />
            </template>
          </template>
        </el-table-column>
      </el-table>
    </main>
  </div>
</template>

<script>
import difference from "lodash/difference";

import axios from "axios";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import CriarColaborador from "@/components/Empresa/CriarColaborador";
import StaffPermissions from "@/components/Settings/Staff/StaffPermissions";

export default {
  components: {
    CriarColaborador,
    StaffPermissions
  },
  filters: {
    toRole: code => (code === "owner" ? "Dono" : "Operador")
  },
  data() {
    return {
      activeRow: -1,
      submitting: false,
      loading: false,
      formerBranches: [],
      tmpBranches: [],
      tmpStaff: {
        name: "",
        role: ""
      },
      showDeleteDialog: false,
      showCreateDialog: false,
      showPermissionDialog: false,
      selectedStaff: null
    };
  },
  computed: {
    ...mapGetters({
      list: types.STAFF_LIST,
      branches: types.BRANCH_LIST,
      storeID: types.AUTH_ACTIVE_STORE,
      user: types.AUTH_USER
    })
  },
  async mounted() {
    this.loading = true;
    try {
      await this.$store.dispatch(types.BRANCH_LIST, {
        storeID: this.storeID
      });
      await this.$store.dispatch(types.STAFF_LIST, { storeID: this.storeID });
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.loading = false;
    this.$bus.$on("new-staff-done", () => {
      this.showCreateDialog = false;
    });
  },
  methods: {
    async handleEditPermissions(id) {
      this.selectedStaff = id;
      this.showPermissionDialog = true;
    },
    async deleteStaff() {
      try {
        this.submitting = true;
        await this.$store.dispatch(types.STAFF_DELETE, {
          storeID: this.storeID,
          staffID: this.toDeleteID
        });
        this.$notify.success("Colaborador removido com sucesso!");
        this.showDeleteDialog = false;
        this.submitting = false;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    handleCreateClick() {
      this.$bus.$emit("new-staff", true);
      this.showCreateDialog = true;
    },
    handleDeleteClick(id) {
      this.toDeleteID = id;
      this.showDeleteDialog = true;
    },
    async handleEditClick(row) {
      this.activeRow = row;
      const currentStaff = this.list[row];
      this.tmpStaff.role = currentStaff.role;
      this.tmpStaff.name = currentStaff.user.name;
      this.tmpStaff.userID = currentStaff.user.id;
      this.tmpStaff.inApp = currentStaff.user.registered_in_app;
      this.tmpBranches = currentStaff.branches.map(it => it.id);
      this.formerBranches = currentStaff.branches.map(it => it.id);
    },
    async handleSaveClick(id) {
      try {
        this.submitting = true;
        if (!this.tmpStaff.inApp) {
          await axios.put(`accounts/users/${this.tmpStaff.userID}`, {
            user: {
              name: this.tmpStaff.name
            }
          });
        }

        await this.$store.dispatch(types.STAFF_UPDATE, {
          storeID: this.storeID,
          staffID: id,
          payload: {
            staff: {
              role: this.tmpStaff.role
            }
          }
        });

        if (this.tmpBranches.length || this.formerBranches.length) {
          const added = difference(this.tmpBranches, this.formerBranches);
          const removed = difference(this.formerBranches, this.tmpBranches);
          added.map(async branch => {
            await this.$store
              .dispatch(types.BRANCH_LINK_STAFF, {
                storeID: this.storeID,
                branchID: branch,
                staffID: id
              })
              .catch(error => {
                if (this.$raven && error.status === 500) {
                  this.$raven.captureException(error.message, { req: error });
                }
                this.$notify.error({ title: error.message });
              });
          });
          removed.map(async branch => {
            await this.$store.dispatch(types.BRANCH_UNLINK_STAFF, {
              storeID: this.storeID,
              branchID: branch,
              staffID: id
            });
          });
        }

        this.$notify.success("Colaborador atualizado com sucesso!");

        this.submitting = false;

        this.activeRow = -1;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>
