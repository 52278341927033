<template>
  <div class="informacoes-empresa">
    <h1>
      Meu cartão digital
      <el-button
        icon="el-icon-edit"
        type="text"
        @click="$router.push({
        name: `Cartão Neopag`
    })"
      >Personalizar</el-button>
    </h1>
    <img v-if="info.card" :src="info.card" class="store-card" />
    <card-graphic v-else big />
    <div>
      <h1>Compartilhar nas redes sociais</h1>
      <section class>
        <img :src="info.fb_banner" class="store-card" />
        <h2 style="margin-top: 14px;">Opções de texto</h2>
        <div class="suggestion-wrapper">
          <el-card :class="{ active: selectedSuggestion === 1 }">
            <div class="text-container">
              <h3>Temos uma novidade para você, o cartão digital {{ info.name }}</h3>
              <p>Fazer compras ficou muito mais fácil e prático com o cartão de crédito da {{ info.name }} no seu celular! Baixe agora no App Neopag e solicite o seu cartão.</p>
              <el-button type="primary" size="mini" @click="handleSuggestion(1)">Selecionar</el-button>
            </div>
          </el-card>
          <el-card :class="{ active: selectedSuggestion === 2 }">
            <div class="text-container">
              <h3>Agora a {{ info.name }} tem um cartão digital!</h3>
              <p>Solicite o seu pelo app Neopag e tenha acesso a benefícios exclusivos!</p>
              <el-button type="primary" size="mini" @click="handleSuggestion(2)">Selecionar</el-button>
            </div>
          </el-card>
          <el-card :class="{ active: selectedSuggestion === 3 }">
            <div class="text-container">
              <h3>Faça já o cartão {{ info.name }}</h3>
              <p>Fazer compras ficou muito mais fácil com o cartão de crédito digital exclusivo da {{ info.name }} Baixe já o app Neopag e solicite o seu!</p>
              <el-button type="primary" size="mini" @click="handleSuggestion(3)">Selecionar</el-button>
            </div>
          </el-card>
        </div>
      </section>
      <div class="network-buttons-wrapper">
        <el-button class="facebook" size="small" @click="shareWithFacebook">Facebook</el-button>
        <social-sharing
          :title="shareTitle"
          :description="shareDescription"
          :quote="shareTitle"
          url="https://www.neopag.com/app-cliente"
          hashtags="neopag,crediarioproprio,cartaodecreditodigital"
          twitter-user="neopagsa"
          inline-template
        >
          <div class="share-buttons-container">
            <network network="twitter">
              <el-button class="twitter" size="small">Twitter</el-button>
            </network>
            <network network="linkedin">
              <el-button class="linkedin" size="small">LinkedIn</el-button>
            </network>
            <network network="whatsapp">
              <el-button class="whatsapp" size="small">Whatsapp</el-button>
            </network>
          </div>
        </social-sharing>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import SocialSharing from "vue-social-sharing";

window.fbAsyncInit = function() {
  FB.init({
    //eslint-disable-line
    appId: "379379342536151",
    autoLogAppEvents: true,
    xfbml: true,
    version: "v3.2"
  });
};

(function(d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");

export default {
  components: {
    SocialSharing
  },
  data() {
    return {
      loading: false,
      selectedSuggestion: 1,
      shareTitle: "",
      shareDescription: ""
    };
  },
  metaInfo() {
    return {
      meta: [
        { property: "og:image", content: this.info.fb_banner },
        { property: "og:image:secure_url", content: this.info.fb_banner },
        { property: "og:title", content: this.shareTitle },
        { property: "og:description", content: this.shareDescription }
      ]
    };
  },
  computed: {
    ...mapGetters({
      info: types.STORE_INFO,
      storeID: types.AUTH_ACTIVE_STORE
    })
  },
  async mounted() {
    this.loading = true;
    try {
      await this.$store.dispatch(types.STORE_INFO, this.storeID);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.shareTitle = `Temos uma novidade para você, o cartão digital ${this.info.name}!`;
    this.shareDescription = `Fazer compras ficou muito mais fácil e prático com o cartão de crédito da ${this.info.name} no seu celular! Baixe agora no App Neopag e solicite o seu cartão!`;
    this.loading = false;
  },
  methods: {
    shareWithFacebook() {
      FB.ui({
        // eslint-disable-line
        app_id: "2188742431161602",
        method: "share_open_graph",
        action_type: "og.shares",
        action_properties: JSON.stringify({
          object: {
            url: "https://www.neopag.com/app-cliente",
            title: this.shareTitle,
            description: this.shareDescription,
            image: this.info.fb_banner
          }
        })
      });
    },
    handleSuggestion(suggestion) {
      this.selectedSuggestion = suggestion;
      switch (suggestion) {
        case 2: {
          this.shareTitle = `Agora a ${this.info.name} tem um cartão digital!`;
          this.shareDescription = `Solicite o seu pelo app Neopag e tenha acesso a benefícios exclusivos!`;
          break;
        }
        case 3: {
          this.shareTitle = `Faça já o cartão ${this.info.name}`;
          this.shareDescription = `Fazer compras ficou muito mais fácil com o cartão de crédito digital exclusivo da ${this.info.name} Baixe já o app Neopag e solicite o seu`;
          break;
        }
        default: {
          this.shareTitle = `Temos uma novidade para você, o cartão digital ${this.info.name}!`;
          this.shareDescription = `Fazer compras ficou muito mais fácil e prático com o cartão de crédito da ${this.info.name} no seu celular! Baixe agora no App Neopag e solicite o seu cartão!`;
          break;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.share-buttons-container {
  display: inline-block;
  width: 100%;
}
.suggestion-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 14px 0;
  .el-card {
    padding: 10px;
    height: 318px;
    position: relative;
    .el-button {
      position: absolute;
      width: 86%;
      bottom: 10px;
      left: 20px;
    }
    @media screen and (max-width: 768px) {
      height: 400px;
    }
    @media screen and (max-width: 768px) {
      height: 400px;
    }
    @media screen and (max-width: 425px) {
      height: 240px;
    }
    @media screen and (max-width: 375px) {
      height: 320px;
    }
  }
  .active {
    padding: 0;
    border: solid 10px $laranja;
  }
  .text-container {
    font-family: Montserrat;
    color: $preto;
    h3 {
      font-weight: 700;
      margin-bottom: 12px;
    }
    p {
      font-size: 14px;
      letter-spacing: 1.125px;
    }
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
  }
}
.network-buttons-wrapper {
  display: flex;
  @media screen and (max-width: 425px) {
    display: block;
  }
  span + span {
    margin-left: 14px;
  }
  .el-button {
    min-width: 140px;
    color: #fafafa;
    span {
      font-family: Montserrat;
      letter-spacing: 1.125px;
    }
    @media screen and (max-width: 425px) {
      display: block;
      width: 100%;
    }
  }
  .facebook {
    background-color: #4267b2;
    border-color: #4267b2;
    margin-right: 14px;
    display: inline-block;
    @media screen and (max-width: 425px) {
      margin-bottom: 14px;
    }
  }
  span {
    .twitter {
      background-color: #1da1f2;
      border-color: #1da1f2;
    }
    .linkedin {
      background-color: #0077b5;
      border-color: #0077b5;
    }
    .whatsapp {
      background-color: #7de66a;
      border-color: #7de66a;
    }
  }
}
.informacoes-empresa {
  .store-card {
    max-width: 320px;
    @media screen and (max-width: 320px) {
      max-width: 280px;
    }
  }
}
</style>
