<template>
  <div class="subscription-container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>Assinatura e pagamento</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-loading="loading" class="plan-card">
      <h2>Plano</h2>
      <div v-if="subscription.plan" class="content">
        <el-tooltip
          :disabled="config.iugu_activated"
          content="Ainda não identificamos o pagamento da sua assinatura."
          placement="bottom"
        >
          <div
            :class="{
              'active': config.iugu_activated
            }"
            :style="{
              backgroundColor: subscription.plan.color
            }"
            class="plan-title"
          >
            <span
              :style="{
                backgroundColor: subscription.plan.color
            }"
            >{{ subscription.plan.mini_label }}</span>
            <!-- <small v-if="!config.iugu_activated && subscription.plan.mini_label !== 'GRATUITO'">inativo</small> -->
          </div>
        </el-tooltip>
        <div class="description">
          <p
            v-if="!config.iugu_activated && subscription.plan.mini_label !== 'GRATUITO'"
          >Seu plano está inatívo entre em contato com o suporte para ativa-lo.</p>
          <p v-else-if="+subscription.plan.price">
            Seu plano será renovado automaticamente
            <br />
            todo dia {{ subscription.due_day }}
          </p>
          <p v-else>Quer alterar seu plano?</p>
          <el-button type="primary" size="mini" round @click="handleChangePlanNavigation">Alterar</el-button>
        </div>
      </div>
      <div v-else>
        <h2 :style="{ backgroundColor: '#6c737c' }" class="plan-title">Gratuito</h2>
        <el-button type="primary" round @click="handleChangePlanNavigation">Alterar</el-button>
      </div>
    </el-card>
    <el-card v-loading="loading" class="consumption-card">
      <h2>Consumo</h2>
      <div class="content">
        <p>
          Cartões ativos:
          <span>{{ storeUsers.active }}</span>
        </p>
        <p>
          Clientes recusados:
          <span>{{ storeUsers.rejected }}</span>
        </p>
      </div>
      <el-button
        :loading="loadingDetails"
        size="mini"
        type="primary"
        round
        @click="handleConsumptionDetatils"
      >Detalhes</el-button>
    </el-card>
    <el-card v-loading="loading" class="payment-methods-card">
      <h2>Forma de pagamento</h2>
      <div class="content">
        <div v-if="clientPaymentData.default_payment_method_id" class="resumo-payment">
          <div :class="activePaymentMethod.data.brand.toLowerCase()" class="flag" />
          <div class="numbers">
            <div
              class="card"
            >{{ activePaymentMethod.data.brand | capitalize }}{{ activePaymentMethod.data.display_number.replace('XXXX-XXXX-XXXX-', ' **** ') }}</div>
            <div
              class="due"
            >Vencimento: {{ activePaymentMethod.data.month }}/{{ activePaymentMethod.data.year }}</div>
          </div>
        </div>
        <div v-else>
          <p class="billet-text">
            Enquanto você não vincular um cartão, enviaremos um boleto para
            <b>{{ storeDetails.email }}</b>
          </p>
        </div>
        <el-button round size="mini" class="last" type="primary" @click="handlePaymentChange">
          <span v-if="paymentMethods.length">Atualizar</span>
          <span v-else>Adcionar</span>
        </el-button>
      </div>
    </el-card>
    <el-card v-loading="loading" class="statements-card">
      <h2>Faturas</h2>
      <el-table
        v-if="subscription.invoices && subscription.invoices.length"
        :header-cell-style="{fontSize: '0.75rem'}"
        :data="subscription.invoices"
        :default-sort="{prop: 'due_date', order: 'ascending'}"
        size="mini"
        style="width: 100%;"
      >
        <el-table-column
          :formatter="row => $subscriptionStatus[row.status || 'pending']"
          prop="amount"
          label="Status"
        />
        <el-table-column
          :formatter="row => $d(new Date(row.due_date + $tz), 'sale_summary')"
          sortable
          prop="due_date"
          label="Venc."
        />
        <el-table-column
          :formatter="row => $n(row.amount, 'currency')"
          sortable
          prop="amount"
          label="Valor"
        />
        <el-table-column prop="url" align="center" label="Ações" width="204">
          <template slot-scope="scope">
            <el-button
              v-if="authOrigin === 'app'"
              size="mini"
              type="primary"
              round
              @click="handleBilletPress(scope.row.url)"
            >Abrir link</el-button>
            <a
              v-else
              :href="scope.row.url"
              target="_blank"
              class="el-button el-button--primary el-button--mini is-round"
              style="text-decoration: none;"
            >Abrir link</a>
            <el-button
              :disabled="scope.row.queries.length <= 0"
              size="mini"
              type="primary"
              round
              @click="handleStatementDetails(scope.row.queries)"
            >Detalhes</el-button>
          </template>
        </el-table-column>
      </el-table>
      <h3 v-else class="empty-state-text">Não há faturas para visualização no momento.</h3>
    </el-card>
    <el-dialog :visible.sync="statementModalVisibility" title="Consultas">
      <div style="max-height: 400px; overflow: auto;">
        <el-table :data="selectedStatementsQueries">
          <el-table-column
            :formatter="row => $d(new Date(row.queried_at), 'sale_summary')"
            prop="queried_at"
            label="Data da consulta"
          />
          <el-table-column prop="user.name" label="Nome" />
          <el-table-column prop="user.cpf" label="CPF" />
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import capitalize from "lodash/capitalize";

export default {
  filters: {
    capitalize: val => capitalize(val)
  },
  data() {
    return {
      loading: true,
      loadingDetails: false,
      submitting: false,
      choice: undefined,
      activePlanIndex: 0,
      activePlan: {
        color: "",
        start: "",
        name: "",
        price: 0
      },
      showChangeDialog: undefined,
      showInvoicesDialog: undefined,
      clientPaymentData: {},
      statementModalVisibility: false,
      selectedStatementsQueries: []
    };
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      storeUsers: types.STORE_USERS,
      storeInfo: types.STORE_INFO,
      plans: types.SUBSCRIPTION_PLANS,
      storeID: types.AUTH_ACTIVE_STORE,
      config: types.SUBSCRIPTION_CONFIG,
      subscription: types.SUBSCRIPTION_DETAILS,
      storeDetails: types.AUTH_ACTIVE_STORE_DETAILS,
      paymentMethods: types.SUBSCRIPTION_PAYMENT_METHODS
    }),
    planPrice() {
      if (this.subscription.plan) {
        return this.$n(this.subscription.plan.price, "currency");
      }
      return this.$n(0, "currency");
    },
    iuguID() {
      return this.storeInfo.iugu_client_identifier;
    },
    activePaymentMethod() {
      return this.paymentMethods[this.paymentMethods.length - 1];
    }
  },
  async mounted() {
    this.fetching = true;
    try {
      await this.$store.dispatch(types.SUBSCRIPTION_PLANS);
      await this.$store.dispatch(types.SUBSCRIPTION_DETAILS, this.storeID);
      await this.$store.dispatch(
        types.SUBSCRIPTION_PAYMENT_METHODS,
        this.storeID
      );
      const resp = await this.$store.dispatch(
        types.GET_SUBSCRIPTION_PAYMENT_METHODS,
        { storeID: this.storeID }
      );
      this.clientPaymentData = resp.data;
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.fetching = false;
    this.loading = false;
  },
  methods: {
    handleBilletPress(url) {
      NativeAppChannel.postMessage(`billet&&${url}`);
    },
    handleChangePlanNavigation() {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage("plan");
      }
      this.$router.push({
        name: "Planos - Visão geral",
        params: {
          planID:
            this.subscription && this.subscription.plan
              ? this.subscription.plan.iugu_identifier
              : ""
        }
      });
    },
    async handleConsumptionDetatils() {
      this.loadingDetails = true;
      try {
        const queries = await this.$store.dispatch(
          types.STORE_CURRENT_QUERIES,
          this.storeID
        );
        this.selectedStatementsQueries = queries;
        this.statementModalVisibility = true;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loadingDetails = false;
    },
    handleStatementDetails(queries) {
      this.selectedStatementsQueries = queries;
      this.statementModalVisibility = true;
    },
    handlePaymentChange() {
      if (this.authOrigin === "app") {
        return NativeAppChannel.postMessage("paymentMethod");
      }
      this.$router.push({
        name: "Assinatura - Meio de pagamento",
        params: {
          planID:
            this.subscription && this.subscription.plan
              ? this.subscription.plan.iugu_identifier
              : ""
        }
      });
    }
  }
};
</script>

<style lang="scss">
.subscription-container {
  max-width: 980px;
  margin: 32px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  .plan-card {
    grid-column-start: 1;
    grid-column-end: 3;
    .content {
      margin-top: 36px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    .plan-title {
      width: fit-content;
      height: 100%;
      text-transform: uppercase;
      padding: 16px;
      @include box-shadow(1);
      border-radius: 5px;

      span {
        opacity: 0.25;
        color: #fff;
        font-weight: 500;
        padding: 4px 1rem;
        border-radius: 12px;
      }
      &.active span {
        opacity: 1;
      }
      small {
        margin-left: 1rem;
        color: crimson;
      }
    }
    p {
      margin-left: auto;
      line-height: 1.125;
      text-align: right;
    }
    .el-button {
      display: block;
      width: 140px;
      margin-top: 16px;
      margin-left: auto;
    }
  }
  .consumption-card {
    max-height: 204px;
    overflow: auto;
    .content {
      margin: 1rem 0;
    }
    p {
      line-height: 1.125;
      font-weight: 300;
    }
  }
  .statements-card {
    grid-column-start: 2;
    grid-column-end: 4;
    max-height: 204px;
    overflow: auto;
    .empty-state-text {
      text-align: center;
      margin-top: 42px;
    }
  }
  .payment-methods-card {
    .el-button {
      display: block;
      width: 140px;
      margin-top: 16px;
      margin-left: auto;
    }
    .resumo-payment {
      display: flex;
      flex-flow: row wrap;
      background: rgba($preto, 0.125);
      border: 1px solid $preto;
      border-radius: 5px;
      padding: 1rem;
      margin: 1rem 0;
      .flag {
        margin-right: 10px;
        flex: 0 30px;
        background-size: 100% 100%;
        width: 30px;
        height: 20px;
        z-index: 2;
        transition: background-position 0.2s ease-in;
        background: url("http://storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/ccic-brands.48dba03883007f86e118f683dcfc4297.png")
          no-repeat;

        &.visa {
          background-position: 0px -22px;
        }
        &.mastercard {
          background-position: 0px -66px;
        }
      }
      .numbers {
        flex: 1;
        text-align: left;
        .card {
          font-family: $title-font;
          color: #000;
          line-height: 1.5;
          letter-spacing: 1px;
        }
        .due {
          font-family: $title-font;
          font-size: 12px;
          line-height: 1.5;
          font-weight: 500;
          color: $preto;
          letter-spacing: 1px;
        }
      }
    }
    .billet-text {
      text-align: center;
      margin-top: 22px;
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 425px) {
    display: block;
    .plan-card {
      margin: 16px 0;
      grid-column: 1;
      .content {
        flex-flow: column wrap;
        margin-top: 16px;
        align-items: center;
        justify-content: space-between;
        p {
          margin: 16px 0;
        }
      }
      .el-button {
        margin: 0 auto;
      }
    }
    .consumption-card {
      margin-bottom: 16px;
    }
    .payment-methods-card {
      margin-bottom: 16px;
    }
  }
}
.resumo-assinatura {
  .resumo-main {
    max-width: 385px;
    margin: 0 auto;
    text-align: center;
    border: 1px solid $laranja;
    padding: 1rem;
    border-radius: 8px;

    h2 {
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      font-family: $title-font;
      color: $preto;
      line-height: 1.5;
      margin-bottom: 1rem;
    }

    .el-button {
      text-transform: uppercase;
      margin-bottom: 2rem;

      &.last {
        margin-bottom: 0;
      }
    }
  }
}
</style>
