<template>
  <div
    :class="{ completed: active || completed }"
    class="step-container"
  >
    <div class="align-center">
      <span>{{ title }}</span>
      <img
        v-if="isCompleted"
        alt="tick-icon"
        class="step-icon"
        src="../../../assets/svg/tick.svg"
      >
    </div>
    <div class="step-bar" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    completed: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCompleted: false,
    }
  },
  watch: {
    completed: function(bool) {
      this.isCompleted = bool;
    }
  }
}
</script>

<style lang="scss" scoped>
  .step-container {
    .align-center {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    span {
      font-family: "Montserrat";
      color: $preto;
      letter-spacing: 1.125px;
    }
    .step-icon {
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
    .step-bar {
      height: 2px;
      width: 100%;
      margin-top: 4px;
      background: #e4e7ed;
      overflow: hidden;
      &:before {
        display: block;
        content: ' ';
        width: 0;
        height: 2px;
        background: $laranja;
      }
    }
  }
  .completed {
    .step-bar {
      &:before {
          animation: fill-right .6s linear forwards;
      }
    }
  }
  @keyframes fill-right {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
</style>
