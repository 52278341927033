<template>
  <div :loading="loading" class="bank-accounts">
    <div class="custom--divider"></div>
    <div v-if="account.number" class="bank-accounts__list">
      <el-row type="flex" justify="center">
        <div class="info__display">
          <h2 class="title">Conta ativa</h2>
        </div>
      </el-row>
      <div class="account">
        <el-card>
          <div slot="header">
            <h2 class="card--title">Dados para depósito</h2>
          </div>
          <div class="ownership--info">
            <p>
              <strong>Banco:</strong>
              {{ account.bank ? account.bank.name : '' }}
            </p>
            <p>
              <strong>Agência:</strong>
              {{ account.branch }}{{ account.branch_digit ? ` - ${account.branch_digit}` : '' }}
            </p>
            <p>
              <strong>Conta:</strong>
              {{ account.number }}{{ account.digit ? ` - ${account.digit}` : '' }}
            </p>
            <p>
              <strong>Tipo da Conta:</strong>
              {{ account.account_type === 'checking' ? 'Corrente' : 'Poupança' }}
            </p>
          </div>
        </el-card>
        <div class="custom--divider"></div>
        <el-card>
          <div slot="header">
            <h2 class="card--title">Titularidade</h2>
          </div>
          <div class="account--info">
            <p v-if="isIuguConfigured">
              <strong>Nome do Responsável:</strong>
              {{ account.owner_name }}
            </p>
            <p v-if="isIuguConfigured">
              <strong>CPF do Responsável:</strong>
              {{ account.owner_cpf }}
            </p>
          </div>
        </el-card>
      </div>
      <div class="custom--divider"></div>
      <el-row type="flex" justify="center">
        <el-button type="success" @click="accountSubmitModal = true">Próxima etapa</el-button>
      </el-row>
    </div>
    <div class="bank-accounts__new">
      <el-row type="flex" justify="center">
        <h2>{{ account.number ? 'Trocar' : 'Adicionar' }} conta</h2>
      </el-row>
      <div class="custom--divider"></div>
      <el-form
        ref="bankDataForm"
        :model="bankDataForm"
        :rules="bankDataFormRules"
        class="bank-form"
        @submit.prevent.native="e => e.preventDefa()"
      >
        <el-row type="flex">
          <el-card style="flex-grow: 2;">
            <div slot="header">
              <h2 class="card--title">Dados para depósito</h2>
            </div>
            <el-form-item label="Banco" size="mini" prop="bank_id">
              <el-select v-model="bankDataForm.bank_id">
                <el-option
                  v-for="bank in banksList"
                  :key="bank.id"
                  :label="`${bank.code} - ${bank.name}`"
                  :value="bank.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item v-if="isPristine" label="Tipo de Titular" size="mini" prop="holder_type">
              <el-select v-model="bankDataForm.holder_type">
                <el-option label="Pessoa Jurídica" value="pj" />
                <el-option label="Pessoal Física" value="pf" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="isPristine" label="Tipo de Conta" size="mini" prop="account_type">
              <el-select v-model="bankDataForm.account_type">
                <el-option label="Corrente" value="checking" />
                <el-option label="Poupança" value="savings" />
              </el-select>
            </el-form-item>
            <div v-if="activeBankRules" class="bank-form__fields">
              <div class="branch">
                <el-form-item
                  :rules="[{
                required: true, message: 'Campo obrigatório', trigger: 'blur'
              }]"
                  size="mini"
                  label="Agência (Sem dígito)"
                  prop="branch"
                >
                  <el-input
                    v-mask="'#'.repeat(activeBankRules.branch_length)"
                    v-model="bankDataForm.branch"
                  />
                </el-form-item>
                <el-form-item
                  v-if="activeBankRules.branch_digit_alphanumeric"
                  :rules="[{
                message: 'Campo obrigatório', trigger: 'blur'
              }]"
                  size="mini"
                  label="Dígito"
                  prop="branch_digit"
                >
                  <el-input v-model="bankDataForm.branch_digit" />
                </el-form-item>
                <el-form-item
                  v-else-if="!activeBankRules.branch_digit_alphanumeric"
                  :rules="[{
                pattern: /\d+/, message: 'Inválido', trigger: 'blur'
              }]"
                  size="mini"
                  label="Dígito"
                  prop="branch_digit"
                >
                  <el-input v-model="bankDataForm.branch_digit" />
                </el-form-item>
              </div>
              <div class="account">
                <el-form-item
                  :rules="[{
                required: true, message: 'Campo obrigatório', trigger: 'blur'
              }, {
                min: activeBankRules.min_account_length, max: activeBankRules.max_account_length,
                message: 'Tamanho inválido', trigger: 'blur'
              }, {
                pattern: /\d+/, message: 'Somente números', trigger: 'blur'
              }]"
                  size="mini"
                  label="Conta (Sem dígito)"
                  prop="number"
                >
                  <el-input v-model="bankDataForm.number" />
                </el-form-item>
                <el-form-item
                  v-if="activeBankRules.digit_length && activeBankRules.digit_alphanumeric"
                  :rules="[{
                min: 1, max: activeBankRules.digit_length,
                message: 'Tamanho inválido', trigger: 'blur'
              }]"
                  size="mini"
                  label="Dígito"
                  prop="digit"
                >
                  <el-input v-model="bankDataForm.digit" />
                </el-form-item>
                <el-form-item
                  v-else-if="activeBankRules.digit_length && !activeBankRules.digit_alphanumeric"
                  size="mini"
                  label="Dígito"
                  prop="digit"
                >
                  <el-input
                    v-mask="'#'.repeat(activeBankRules.digit_length)"
                    v-model="bankDataForm.digit"
                  />
                </el-form-item>
              </div>
            </div>
          </el-card>
        </el-row>
        <div class="custom--divider"></div>
        <el-row type="flex">
          <el-card style="flex-grow: 2;">
            <div slot="header">
              <h2 class="card--title">Titularidade</h2>
            </div>
            <div class="bank-form__aditional">

              <el-form-item
                v-if="isIuguConfigured"
                size="mini"
                label="Nome do Responsável"
                prop="owner_name"
              >
                <el-input v-model="bankDataForm.owner_name" />
              </el-form-item>
              <el-form-item
                v-if="isIuguConfigured"
                size="mini"
                label="CPF do Responsável"
                prop="owner_cpf"
              >
                <el-input v-mask="'###.###.###-##'" v-model="bankDataForm.owner_cpf" />
              </el-form-item>
            </div>
          </el-card>
        </el-row>
        <div class="button-container">
          <el-button
            :loading="submitting"
            type="primary"
            size="medium"
            @click="accountSubmitModal = true"
          >{{ account.number ? 'Substituir' : 'Salvar' }} conta</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog class="account-dialog" v-if="!account.number" :visible.sync="accountSubmitModal" :fullscreen="$isMobile">
      <h1 class="account-dialog--title">Atenção</h1>
      <p>Confirme os dados abaixo, pois uma vez enviados não será possível alterá-los</p>
      <div class="account-dialog--info-wrapper">
        <el-card>
          <div slot="header" class="card--title">Dados da conta bancária</div>
          <p>
            Banco:
            <b>{{bankName}}</b>
          </p>
          <p>
            Tipo de Conta:
            <b>{{accountType}}</b>
          </p>
          <p>
            Agência:
            <b>{{bankDataForm.branch}}</b>
          </p>
          <p>
            Dígito da Agência:
            <b>{{bankDataForm.branch_digit}}</b>
          </p>
          <p>
            Conta:
            <b>{{bankDataForm.number}}</b>
          </p>
          <p>
            Dígito da Conta:
            <b>{{bankDataForm.digit}}</b>
          </p>
        </el-card>
        <el-card>
          <div slot="header" class="card--title">Titular da conta bancária</div>
          <p>
            Nome do Responsável:
            <b>{{bankDataForm.owner_name}}</b>
          </p>
          <p>
            CPF do Responsável:
            <b>{{bankDataForm.owner_cpf}}</b>
          </p>
          <p>
            Titular da Conta:
            <b>{{holderType}}</b>
          </p>
        </el-card>
      </div>
      <div class="buttons--card__wrapper">
        <el-button type="success" @click="submitForm('bankDataForm')">Sim, confirmar dados e enviar</el-button>
        <el-button type="danger" @click="accountSubmitModal = false">Não, alterar dados</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import moment from "moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import isCPF from "@/helpers/isCPF";

library.add(faBarcode);

export default {
  props: {
    isPristine: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const checkCPF = (rule, value, callback) => {
      const formatedCPF = isCPF(value.replace(/[.-]/g, ""));
      if (!formatedCPF) {
        return callback(new Error("Por favor insira um CPF válido"));
      } else {
        return callback();
      }
    };
    return {
      accountSubmitModal: false,
      loading: true,
      submitting: false,
      showAccountForm: false,
      bankDataForm: {
        // is_default: true,
        holder_type: "pj",
        account_type: "checking",
        bank_id: "",
        branch: "", //agencia
        branch_digit: "", //digito ag
        number: "", //conta
        digit: "", //digito conta
        owner_name: "",
        owner_cpf: "",
        price_range_min: 0,
        price_range_max: 0,
        sell_physical_prodcuts: true
      },
      bankDataFormRules: {
        bank_id: [
          { required: true, message: "Campo obrigatório", trigger: "blur" }
        ],
        owner_name: [
          { required: true, message: "Campo obrigatório", trigger: "blur" }
        ],
        owner_cpf: [{ required: true, validator: checkCPF, trigger: "change" }]
      }
    };
  },
  computed: {
    ...mapGetters({
      storeID: types.AUTH_ACTIVE_STORE,
      account: types.STORE_BANK_ACCOUNT,
      banksList: types.STORE_BANKS,
      billetsBalances: types.STORE_BILLETS_BALANCES,
      store: types.STORE_INFO
    }),
    activeBankRules() {
      if (this.banksList.length) {
        return this.banksList.find(it => it.id === this.bankDataForm.bank_id);
      }
      return false;
    },
    isIuguConfigured() {
      if (this.store.billet_config === "iugu") {
        return true;
      }
      return false;
    },
    holderType() {
      switch (this.bankDataForm.holder_type) {
        case "pj":
          return "Pessoa Jurídica";
        case "pf":
          return "Pessoa Física";
        default:
          return "";
      }
    },
    accountType() {
      switch (this.bankDataForm.account_type) {
        case "checking":
          return "Conta Corrente";
        case "savings":
          return "Conta Poupança";
        default:
          return "";
      }
    },
    bankName() {
      if (this.bankDataForm.bank_id) {
        const bank = this.banksList.find(
          it => it.id === this.bankDataForm.bank_id
        );
        return bank.name;
      } else {
        return "";
      }
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.STORE_BANKS);
      await this.$store.dispatch(
        types.STORE_BANK_ACCOUNT_DETAILS,
        this.storeID
      );
      await this.$store.dispatch(types.STORE_WITHDRAWAL_LIST, this.storeID);
      await this.$store.dispatch(
        types.STORE_GET_BILLETS_BALANCES,
        this.storeID
      );
      this.loading = false;
    } catch (error) {
      this.loading = false;
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    submitNewBank() {
      this.$refs.bankDataForm.validate(valid => {
        if (valid) {
          return this.handleBankPost();
        }
        return false;
      });
    },
    async submitForm(formName) {
      this.submitting = true;
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const payload = this.bankDataForm;
          payload.owner_cpf.replace(/\D/g, "");
          try {
            let payload = this.bankDataForm;
            payload.owner_cpf.replace(/\D/g, "");
            if (!this.isPristine) {
              delete payload["holder_type"];
            }
            await this.$store.dispatch(types.STORE_BANK_ACCOUNT, {
              store_id: this.storeID,
              ...payload
            });
            this.$notify.success({
              title: "Sucesso",
              message: "Os dados foram salvos na plataforma"
            });
            this.loading = false;
            this.$emit("success");
            this.showAccountForm = false;
            this.account = types.STORE_BANK_ACCOUNT;
            this.$emit("onSubmit");
          } catch (error) {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          }
        } else {
          return false;
        }
      });
      this.submitting = false;
    }
    // beforeRemove(file) {
    //   return this.$confirm(`Remover ${file.name}？`);
    // },
    // handleSubmit() {
    //   this.$refs.upload.submit();
    // },
    // async handleUpload(upload) {
    //   try {
    //     const file = upload.file;
    //     const payload = new FormData();
    //     payload.append("file", file);
    //     this.uploading = true;
    //     await this.$store.dispatch(types.STORE_BANK_DOMICILE_UPLOAD, {
    //       bankAccountId: this.account.id,
    //       payload
    //     });
    //     this.$notify.success({
    //       message: "Arquivo enviado com sucesso!"
    //     });
    //     this.$refs.upload.clearFiles();
    //     this.uploading = false;
    //   } catch (error) {
    //     if (this.$raven && error.status === 500) {
    //       this.$raven.captureException(error.message, { req: error });
    //     }
    //     this.$notify.error({ title: error.message });
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.custom--divider {
  margin: 0.5rem 0;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  .el-button {
    width: 100%;
    max-width: 445px;
  }
}
.info__display {
  display: flex;
  flex-wrap: wrap;
}
.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #6c737c;
  line-height: 4rem;
}

.info {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  align-content: flex-start;
  font-family: $title-font;

  .el-form-item {
    margin-bottom: 0;
  }

  &__display {
    line-height: 32px;
    .el-button {
      margin-left: 0.5rem;
    }
    @include medium-down {
      flex: 0 100%;
      line-height: 1.45;
      font-size: 14px;
      margin-bottom: 4px;
      min-height: 32px;
      .el-button {
        margin-left: 4px;
      }
    }
  }
}
.bank-accounts {
  display: flex;
  flex-direction: column;
  align-items: center;
  .el-icon {
    color: darken(green, 5);
  }
  h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  &__list {
    .account {
      font-size: 14px;
      .ownership--info {
        p {
          margin-bottom: 0.5rem;
        }
      }
      .account--info {
        p {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .bank-form {
    .el-row {
      .el-form-item + .el-form-item {
      }
      @media screen and (max-width: 425px) {
        .el-form-item + .el-form-item {
          margin-left: 0;
        }
      }
    }
    &__fields {
      .el-form-item {
        width: 100%;
        max-width: 214px;
      }
      .branch {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        .el-form-item + .el-form-item {
          margin-left: 1rem;
          max-width: 60px;
        }
      }
      .account {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        .el-form-item + .el-form-item {
          margin-left: 1rem;
          max-width: 60px;
        }
      }
    }

    &__aditional {
      .el-form-item {
        width: 100%;
      }
      .el-form-item + .el-form-item {
      }
      @media screen and (max-width: 425px) {
        .el-form-item + .el-form-item {
          margin-left: 0;
        }
      }
    }
  }
}
.card--title {
  color: $laranja;
  font-weight: bold;
}
.account-dialog {
  &--title {
    color: $laranja;
    font-weight: bold;
  }

  &--info-wrapper {
    display: grid;
    @media screen and(max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    margin: 1rem 0;
    p {
      margin: 13px 0;
    }
  }
  .card--title {
    color: $laranja;
    font-weight: bold;
  }
  .buttons--card__wrapper {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    @media screen and(max-width: 767px) {
      flex-direction: column;
      button {
        &:nth-child(2) {
          margin-top: 1rem;
        }
      }
    }
  }
}
</style>
