<template>
  <div class="editar-planos">
    <el-row>
      <el-dialog :fullscreen="true" :visible.sync="showPlanner">
        <credit-planner config />
      </el-dialog>
      <div class="editar-planos__wizard">
        <template v-if="!is_neopag_credit_config">
          <el-button
            size="small"
            icon="el-icon-edit-outline"
            type="success"
            @click="showPlanner = true"
            >Assistente de configuração</el-button
          >
        </template>
      </div>
    </el-row>
    <!-- Assistente de configuração -->
    <el-row :gutter="10">
      <el-col :xs="24" :md="14" :lg="24">
        <div class="editar-planos__card-header">
          <h4>Configurações</h4>
          <template v-if="is_neopag_credit_config">
            <h5>NEOPAG CREDIT</h5>
          </template>
        </div>
      </el-col>
    </el-row>
    <!-- Título recebimentos -->
    <el-row :gutter="10" class="editar-planos__card-info">
      <el-col :lg="8" :md="8" :sm="24" :xs="24">
        <el-card>
          <div slot="header" class="clearfix">
            <span>
              <b>Configurações</b>
            </span>
            <template v-if="!is_neopag_credit_config">
              <el-button
                v-if="!editingConfig"
                :loading="mounting"
                style="float: right"
                icon="el-icon-edit"
                size="mini"
                @click="editingConfig = true"
                >EDITAR</el-button
              >
              <el-button
                v-if="editingConfig"
                style="float: right"
                icon="el-icon-check"
                size="mini"
                type="success"
                @click="
                  handlePaymentsSave();
                  handleSalesSave();
                "
              />
              <el-button
                v-if="editingConfig"
                style="float: right"
                icon="el-icon-close"
                size="mini"
                type="secondary"
                @click="editingConfig = false"
              />
            </template>
          </div>
          <div>
            <p>Carência para atrasos:</p>
            <el-input
              v-if="editingConfig"
              v-model="paymentForm.overdue.days"
              class="number-input"
              size="mini"
              @keyup.native.enter="handlePaymentsSave()"
            />
            <p v-if="!editingConfig">
              <b>{{ config.overdue.days }} dia(s)</b>
            </p>
          </div>
          <div>
            <p>Juros por atraso:</p>
            <el-input
              v-if="editingConfig"
              v-model="paymentForm.overdue.interest"
              class="number-input"
              size="mini"
              @keyup.native.enter="handlePaymentsSave()"
            />
          </div>
          <p v-if="!editingConfig">
            <b>{{ config.overdue.interest }}%</b>
          </p>
          <div class="editar-planos__edit-invoice-date">
            <p>Dias de vencimento:</p>
            <el-select
              v-if="editingConfig"
              v-model="saleForm.due_days"
              size="mini"
              style="width: 100%"
              multiple
            >
              <el-option
                v-for="item in Array.from(Array(28).keys()).map((it) => ++it)"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
            <p v-else>
              <b>{{
                config.due_days && config.due_days.length > 0
                  ? config.due_days.join(", ")
                  : "Todos"
              }}</b>
            </p>
          </div>
          <div class="editar-planos__edit-invoice-date">
            <p>Fechamento da fatura:</p>
            <el-input
              v-if="editingConfig"
              v-model="saleForm.cutoff_days"
              size="mini"
              @keyup.native.enter="handleSalesSave()"
            />
            <p v-else-if="!editingSales">
              <b>{{ config.cutoff_days }} dias</b>
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- config de recebimentos -->
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="24">
        <div class="editar-planos__card-header">
          <div style="display: flex; align-items: center; flex-wrap: wrap">
            <h4>Planos de Vendas</h4>
            <template>
              <el-button
                v-if="!editingSales"
                :loading="mounting"
                icon="el-icon-edit"
                size="mini"
                @click="editingSales = true"
                >EDITAR</el-button
              >
              <el-button
                v-if="editingSales"
                icon="el-icon-close"
                size="mini"
                type="secondary"
                @click="editingSales = false"
                >Fechar</el-button
              >
            </template>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- Título vendas -->
    <el-row v-if="!editingSales" :gutter="10">
      <el-col
        v-for="plano in plans"
        :key="plano.id"
        :lg="8"
        :md="12"
        :sm="24"
        :xs="24"
      >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>
              <b>{{ plano.name }}</b> <i v-if="plano.is_neopag_credit_plan" style="font-size:10px; color:#ff5e44;">[neopag.crédito]</i>
            </span>
          </div>
          <div>
            <p>
              <el-tooltip
                class="item"
                effect="light"
                content="Em quantas vezes seu produto pode ser parcelado"
                placement="top"
              >
                <b class="editar-planos__tooltip">Quantidade de parcelas:</b>
              </el-tooltip>
              {{ plano.installment.from }} até {{ plano.installment.to }} vezes
            </p>
            <p>
              <el-tooltip
                class="item"
                effect="light"
                content="Taxa de juros cobrada em cada parcela do produto"
                placement="top"
              >
                <b class="editar-planos__tooltip"
                  >Percentual de juros mensal:</b
                >
              </el-tooltip>
              {{ plano.interest }}% ao mês
            </p>
            <p>
              <el-tooltip
                class="item"
                effect="light"
                content="Porcentagem da entrada sobre o valor total do produto"
                placement="top"
              >
                <b class="editar-planos__tooltip"
                  >Percentual mínimo de entrada:</b
                >
              </el-tooltip>
              {{ plano.down_payment }}%
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- Cards de planos de venda -->
    <el-row>
      <plan-edit
        @handleCloseEdit="editingSales = false"
        v-if="editingSales"
        :handle-sales-save="handleSalesSave"
        type="sale"
      />
    </el-row>
    <!-- Componente edição de planos -->
    <el-row>
      <el-col :lg="24" :md="24" :sm="24" :xs="24">
        <div class="editar-planos__card-header">
          <div style="display: flex; align-items: center; flex-wrap: wrap">
            <h4>Planos de acordos e reparcelamentos</h4>
            <el-button
              v-if="!editingDeals"
              :loading="mounting"
              icon="el-icon-edit"
              size="mini"
              @click="editingDeals = true"
              >EDITAR</el-button
            >
            <el-button
              v-if="editingDeals"
              icon="el-icon-close"
              size="mini"
              type="secondary"
              @click="editingDeals = false"
              >Fechar</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- Title acordos e reparcelamentos -->
    <el-row v-if="!editingDeals" :gutter="10">
      <el-col
        v-for="deal_plan in deal_plans"
        :key="deal_plan.id"
        :lg="8"
        :md="12"
        :sm="24"
        :xs="24"
      >
        <el-card>
          <div slot="header" class="editar-planos__card clearfix">
            <span>
              <b>{{ deal_plan.name }}</b>
            </span>
            <el-button
              v-if="editingDeals"
              style="float: right"
              icon="el-icon-check"
              size="mini"
              type="success"
              @click="handleDealsSave"
            />
          </div>
          <div>
            <p>
              <el-tooltip
                class="item"
                effect="light"
                content="Em quantas vezes seu produto pode ser parcelado"
                placement="top"
              >
                <b class="editar-planos__tooltip">Quantidade de parcelas:</b>
              </el-tooltip>
              {{ deal_plan.installment.from }} até
              {{ deal_plan.installment.to }} vezes
            </p>
            <p>
              <el-tooltip
                class="item"
                effect="light"
                content="Taxa de juros cobrada em cada parcela do produto"
                placement="top"
              >
                <b class="editar-planos__tooltip"
                  >Percentual de juros mensal:</b
                >
              </el-tooltip>
              {{ deal_plan.interest }}% ao mês
            </p>
            <p>
              <el-tooltip
                class="item"
                effect="light"
                content="Porcentagem da entrada sobre o valor total do produto"
                placement="top"
              >
                <b class="editar-planos__tooltip"
                  >Percentual mínimo de entrada:</b
                >
              </el-tooltip>
              {{ deal_plan.down_payment }}%
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- Card acordos e parcelamentos -->
    <el-row :gutter="10">
      <el-col :xs="24" :md="24" :lg="24">
        <plan-edit
          @handleCloseEdit="editingDeals = false"
          v-if="editingDeals"
          :handle-sales-save="handleSalesSave"
          type="deal"
        />
      </el-col>
    </el-row>
    <!-- Ediçãod e planos de acordos e reparcelamentos -->
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import CreditPlanner from "@/components/CreditPlanner";
import PlanEdit from "@/components/PlanEdit";
export default {
  components: {
    PlanEdit,
    CreditPlanner,
  },
  data() {
    return {
      mounting: true,
      submitting: false,
      editingSales: false,
      editingConfig: false,
      editingDeals: false,
      showPlanner: false,
      is_neopag_credit_config: false,
      paymentForm: {
        overdue: {
          interest: 0,
          days: 0,
        },
      },
      saleForm: {
        due_days: [],
        cutoff_days: 0,
      },
      dealForm: {
        charge: false,
        charge_amount: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      plans: types.STORE_PLANS,
      config: types.STORE_CONFIG,
      storeID: types.AUTH_ACTIVE_STORE,
      deal_plans: types.STORE_DEAL_PLANS,
    }),
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.STORE_PLANS, this.storeID);
      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
      await this.$store.dispatch(types.STORE_DEAL_PLANS, this.storeID);
      this.mounting = false;
      this.setDefaultValues();
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    setDefaultValues() {
      this.saleForm.due_days = this.config.due_days;
      this.saleForm.cutoff_days = this.config.cutoff_days;
      this.paymentForm.overdue.days = this.config.overdue.days;
      this.paymentForm.overdue.interest = this.config.overdue.interest;
      this.is_neopag_credit_config = this.config.is_neopag_credit_config;
    },
    handlePaymentsSave() {
      const days = (this.paymentForm.overdue.days + "").replace(",", ".");
      const interest = (this.paymentForm.overdue.interest + "").replace(
        ",",
        "."
      );
      const payload = {
        configs: { overdue: { days: +days, interest: +interest } },
      };
      this.updateConfig(payload);
    },
    handleSalesSave() {
      const payload = {
        configs: {
          due_days: this.saleForm.due_days,
          cutoff_days: this.saleForm.cutoff_days,
        },
      };
      this.updateConfig(payload);
    },
    handleDealsSave() {
      return false;
    },
    async updateConfig(payload) {
      this.submitting = true;
      await this.$store
        .dispatch(types.STORE_CONFIG_UPDATE, {
          storeID: this.storeID,
          payload,
        })
        .then(() => {
          this.$notify.success("Configurações atualizadas com sucesso!");
        })
        .catch(() => {
          this.$notify.success(
            "Não foi possível atualizar suas configurações!"
          );
        })
        .finally(() => {
          this.editingConfig = false;
          this.editingSales = false;
          this.editingSalesConfig = false;
          this.editingDeals = false;
          this.submitting = false;
        });
    },
  },
};
</script>
<style lang="scss">
.editar-planos {
  font-size: 12px !important;
  .clearfix {
    font-size: 1rem;
  }
  b,
  span {
    color: $preto;
  }
  > .el-row > .el-col {
    margin-bottom: 1rem;
  }
  &__wizard {
    span {
      color: inherit;
    }
    .el-button {
      text-transform: uppercase;
    }
    margin: 1rem 0;
  }
  &__edit-invoice-date {
  }
  &__card-header {
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid rgba($preto, 0.125);
    h4 {
      font-weight: 500;
      margin-right: 1rem;
    }
  }
  &__card-info {
    font-family: $title-font;
    color: $preto;
    .el-col:nth-child(even) {
      text-align: right;
    }
    .number-input {
      max-width: 60px;
      .el-input__inner {
        text-align: right;
      }
    }
  }
  &__card-table {
    padding-top: 1rem;
    .cell {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: $title-font;
      font-size: 11px;
      line-height: 1;
      padding: 0;
    }
    .el-table__header .cell {
      font-size: 12px;
    }
    .el-table__header {
      padding: 0;
    }
  }
  &__tooltip {
    cursor: pointer;
  }
  .el-card {
    p {
      margin-top: 1rem;
    }
  }
}
</style>
