<template>
  <section v-loading="loading" class="billet-step3-container">
    <confirmation-step :load="loading" @loading="val => loading = val" />
  </section>
</template>

<script>
import ConfirmationStep from "@/components/Settings/Billets/ConfirmationStep";

export default {
  components: {
    ConfirmationStep,
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>
