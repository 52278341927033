<template>
  <div class="criar-colaborador">
    <el-steps :active="currentCreateStep" align-center>
      <el-step title="CPF" icon="el-icon-search" />
      <el-step title="Dados" icon="el-icon-edit" />
      <el-step title="Cargo" icon="el-icon-service" />
    </el-steps>
    <template v-if="currentCreateStep === 1">
      <div class="buscar-cpf">
        <el-input
          v-mask="'###.###.###-##'"
          ref="cpf"
          v-model="cpfQuery"
          size="mini"
          autofocus
          clearable
          placeholder="Digite o CPF"
          @keyup.enter.native="handleCPFSearch"
        >
          <button
            slot="append"
            class="search-button el-button el-button--default el-button--mini"
            @click="handleCPFSearch"
          >
            <i v-if="searching" class="el-icon el-icon-loading" />
            <i v-else class="el-icon el-icon-search" />
          </button>
        </el-input>
      </div>
    </template>
    <template v-else-if="currentCreateStep === 2">
      <br />
      <el-alert
        v-if="inApp"
        :closable="false"
        title="CPF já cadastrado!"
        description="Esse colaborador deve usar a senha do app para entrar na loja"
        type="warning"
      />
      <br v-if="inApp" />
      <el-form
        ref="newStaff"
        :model="newStaff"
        :rules="newStaffRules"
        label-width="100px"
        status-icon
        size="mini"
        @submit.prevent.native="e => e.preventDefault()"
      >
        <el-form-item :disabled="inApp" label="Nome" prop="name" required>
          <el-input :disabled="inApp" v-model="newStaff.name" />
        </el-form-item>
        <el-form-item :disabled="inApp" label="Telefone" prop="phone" required>
          <el-input
            v-mask="['XX XXXX-XXXX', 'XX XXXX-XXXXX']"
            :disabled="inApp"
            v-model="newStaff.phone"
          />
        </el-form-item>
        <el-form-item v-if="!inApp" label="Senha" prop="password" required>
          <el-input v-model="newStaff.password" type="password" />
        </el-form-item>
        <el-form-item v-if="!inApp" label="Confirmar" prop="password2" required>
          <el-input v-model="newStaff.password2" type="password" />
        </el-form-item>
        <el-form-item>
          <template v-if="inApp">
            <el-button type="success" size="mini" @click="currentCreateStep = 3">CONTINUAR</el-button>
          </template>
          <template v-else>
            <el-button :loading="submitting" type="success" size="mini" @click="validateForm">SALVAR</el-button>
          </template>
        </el-form-item>
      </el-form>
    </template>
    <template v-else-if="currentCreateStep === 3">
      <br />
      <el-form inline style="text-align: center;" size="mini">
        <el-form-item label="Cargo" prop="role">
          <el-select v-model="newStaff.role" style="width: 150px">
            <el-option value="owner" label="Dono" />
            <el-option value="operator" label="Operador" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="submitting"
            size="mini"
            type="success"
            @click="saveStaff"
          >CRIAR COLABORADOR</el-button>
        </el-form-item>
      </el-form>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import axios from "axios";
import phone from "@/helpers/phone";

export default {
  filters: {
    toRole: code => (code === "owner" ? "Dono" : "Operador")
  },
  props: {
    branch: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      egg: new this.$egg(),
      cpfQuery: "",
      currentCreateStep: 1,
      submitting: false,
      searching: false,
      newStaff: {
        cpf: "",
        name: "",
        role: "",
        branches: []
      },
      newStaffRules: {
        name: [{ required: true, message: "Campo obrigatório" }],
        password: [{ required: true, message: "Campo obrigatório" }],
        password2: [{ required: true, message: "Campo obrigatório" }],
        phone: [{ required: true, message: "Campo obrigatório" }]
      }
    };
  },
  computed: {
    ...mapGetters({
      branches: types.BRANCH_LIST,
      storeID: types.AUTH_ACTIVE_STORE
    })
  },
  mounted() {
    this.$bus.$on("new-staff", () => {
      this.reset();
    });
    this.registerEggs();
  },
  methods: {
    registerEggs() {
      this.egg
        .addCode("left,n,o,v,o", () => {
          this.cpfQuery = "37020435866";
        })
        .listen();
      this.egg
        .addCode("left,a,n,a,l,i,s,e", () => {
          this.cpfQuery = "54791482573";
        })
        .listen();
      this.egg
        .addCode("left,c,o,n,v,i,d,a,d,o", () => {
          this.cpfQuery = "59139190501";
        })
        .listen();
      this.egg
        .addCode("left,a,t,i,v,o", () => {
          this.cpfQuery = "47030389867";
        })
        .listen();
    },
    previousStep() {
      if (this.currentCreateStep > 1) this.currentCreateStep--;
    },
    nextStep() {
      if (this.currentCreateStep < 3) this.currentCreateStep++;
    },
    async handleCPFSearch() {
      try {
        this.searching = true;
        const response = await this.$store.dispatch(types.STAFF_SEARCH_BY_CPF, {
          CPF: this.cpfQuery.replace(/\D/gi, "")
        });
        this.searching = false;
        this.inApp = response.data.registered_in_app;
        this.newStaff.id = response.data.id;

        if (this.inApp) {
          const userData = await this.$store.dispatch(
            types.CLIENT_BY_ID,
            this.newStaff.id
          );

          this.newStaff.name = userData.name;
          this.newStaff.phone = userData.phone.replace("+55", "");
        } else {
          this.newStaffRules.password = [
            { required: true, message: "Campo obrigatório" },
            { min: 8, message: "8 caractéres no mínimo", trigger: "blur" }
          ];
          this.newStaffRules.password2 = [
            { required: true, message: "Campo obrigatório" },
            {
              validator: (r, v, cb) => {
                if (v !== this.newStaff.password) {
                  cb(new Error("Senhas não coincidem"));
                } else {
                  cb();
                }
              },
              trigger: "blur"
            },
            { min: 8, message: "8 caractéres no mínimo", trigger: "blur" }
          ];
        }

        this.currentCreateStep = 2;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    validateForm() {
      this.$refs.newStaff.validate(valid => {
        if (valid) {
          this.updateUser();
        } else {
          return false;
        }
      });
    },
    async updateUser() {
      this.submitting = true;

      await axios.put(`/accounts/users/${this.newStaff.id}`, {
        user: {
          name: this.newStaff.name,
          phone: phone.unformat(this.newStaff.phone),
          password: this.newStaff.password
        }
      });

      this.submitting = false;

      this.currentCreateStep = 3;
    },
    async saveStaff() {
      try {
        this.submitting = true;
        const response = await this.$store.dispatch(types.STAFF_CREATE, {
          storeID: this.storeID,
          payload: {
            user_id: this.newStaff.id,
            role: this.newStaff.role
          }
        });

        if (this.branch) {
          await this.$store.dispatch(types.BRANCH_LINK_STAFF, {
            storeID: this.storeID,
            branchID: this.branch,
            staffID: response.data.id
          });
        }

        this.reset();
        this.submitting = false;
        this.$bus.$emit("new-staff-done");
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    reset() {
      this.inApp = false;
      this.currentCreateStep = 1;
      delete this.newStaffRules.password;
      delete this.newStaffRules.password2;
      this.newStaff = {
        cpf: "",
        name: "",
        role: "",
        branches: []
      };
    }
  }
};
</script>

<style lang="scss">
.buscar-cpf {
  max-width: 320px;
  margin: 2rem auto 0;
}
</style>
