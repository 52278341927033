<template>
  <el-card
    v-loading="loading"
    class="reset-password-card"
  >
    <div v-if="!resetedPassword">
      <h1>Resetar senha</h1>
      <el-alert
        :closable="false"
        title="Senha deve conter no mínimo 8 caracteres"
        type="warning"
      />
      <el-input
        v-model="passwordInput"
        placeholder="Senha..."
      />
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Resetar senha
      </el-button>
    </div>
    <div v-else>
      <i class="el-icon-success" />
      <h1>Senha Resetada!</h1>
    </div>
  </el-card>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      passwordInput: '',
      resetedPassword: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      authUser: types.AUTH_USER,
    }),
  },
  methods: {
    onSubmit() {
      if (this.passwordInput.length < 8) {
        this.$notify.error({
          title: 'A senha precisa conter no minimo 8 caracteres',
        });
      } else {
        this.loading = true;
        this.$store.dispatch(
          types.AUTH_RESET_PASSWORD,
          { userId: this.authUser.id, password: this.passwordInput },
        )
        .then((resp) => {
          this.loading = false;
        })
        .catch((error) => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        })
        .finally(() => {
          this.resetedPassword = true;
        });
      }
    }
  }
}
</script>

<style lang="scss">
  .reset-password-card {
    max-width: 340px;
    margin: 0 auto;
    h1 {
      margin: 0;
      text-align: center;
    }
    .el-input {
      margin: 16px 0;
    }
    .el-alert {
      margin-top: 16px;
    }
    .el-button {
      width: 100%;
    }
    i {
      width: 100%;
      text-align: center;
      font-size: 68px;
      color: green;
      margin-bottom: 16px;
    }
  }
</style>
